"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_COMMITTEE_KIND = exports.KIND_COMPUTE_EXECUTOR = exports.KIND_INVALID = exports.ROLE_BACKUP_WORKER = exports.ROLE_WORKER = exports.ROLE_INVALID = void 0;
/**
 * RoleInvalid is an invalid role (should never appear on the wire).
 */
exports.ROLE_INVALID = 0;
/**
 * RoleWorker indicates the node is a worker.
 */
exports.ROLE_WORKER = 1;
/**
 * RoleBackupWorker indicates the node is a backup worker.
 */
exports.ROLE_BACKUP_WORKER = 2;
/**
 * KindInvalid is an invalid committee.
 */
exports.KIND_INVALID = 0;
/**
 * KindComputeExecutor is an executor committee.
 */
exports.KIND_COMPUTE_EXECUTOR = 1;
/**
 * MaxCommitteeKind is a dummy value used for iterating all committee kinds.
 */
exports.MAX_COMMITTEE_KIND = 2;
