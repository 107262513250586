/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An entity registered at the consensus layer.
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * The public key identifying this entity.
     * @type {string}
     * @memberof Entity
     */
    id: string;
    /**
     * The staking address belonging to this entity; derived from the entity's public key.
     * @type {string}
     * @memberof Entity
     */
    address: string;
    /**
     * The vector of nodes owned by this entity.
     * @type {Array<string>}
     * @memberof Entity
     */
    nodes: Array<string>;
}

export function EntityFromJSON(json: any): Entity {
    return EntityFromJSONTyped(json, false);
}

export function EntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'],
        'nodes': json['nodes'],
    };
}

export function EntityToJSON(value?: Entity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'address': value.address,
        'nodes': value.nodes,
    };
}


