/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountDelegationsInfo
 */
export interface AccountDelegationsInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    validatorAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    validatorName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    entityAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    shares: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDelegationsInfo
     */
    amount: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDelegationsInfo
     */
    active: boolean;
}

export function AccountDelegationsInfoFromJSON(json: any): AccountDelegationsInfo {
    return AccountDelegationsInfoFromJSONTyped(json, false);
}

export function AccountDelegationsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDelegationsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validatorAddress': json['validatorAddress'],
        'validatorName': json['validatorName'],
        'icon': json['icon'],
        'entityAddress': json['entityAddress'],
        'shares': json['shares'],
        'amount': json['amount'],
        'active': json['active'],
    };
}

export function AccountDelegationsInfoToJSON(value?: AccountDelegationsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validatorAddress': value.validatorAddress,
        'validatorName': value.validatorName,
        'icon': value.icon,
        'entityAddress': value.entityAddress,
        'shares': value.shares,
        'amount': value.amount,
        'active': value.active,
    };
}


