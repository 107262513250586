/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountRewardExportRequest
 */
export interface AccountRewardExportRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountRewardExportRequest
     */
    account: string;
}

export function AccountRewardExportRequestFromJSON(json: any): AccountRewardExportRequest {
    return AccountRewardExportRequestFromJSONTyped(json, false);
}

export function AccountRewardExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRewardExportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': json['account'],
    };
}

export function AccountRewardExportRequestToJSON(value?: AccountRewardExportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
    };
}


