"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = exports.METHOD_PARAMETERS = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'rewards';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
// Queries.
exports.METHOD_PARAMETERS = 'rewards.Parameters';
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    queryParameters() {
        return this.query(exports.METHOD_PARAMETERS);
    }
}
exports.Wrapper = Wrapper;
