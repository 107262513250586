/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuntimeRoundInfoResponse
 */
export interface RuntimeRoundInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof RuntimeRoundInfoResponse
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    runtimeId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    runtimeName?: string;
    /**
     * 
     * @type {number}
     * @memberof RuntimeRoundInfoResponse
     */
    round?: number;
    /**
     * 
     * @type {number}
     * @memberof RuntimeRoundInfoResponse
     */
    timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof RuntimeRoundInfoResponse
     */
    header_type?: number;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    previous_hash?: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    io_root?: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    state_root?: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeRoundInfoResponse
     */
    messages_hash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RuntimeRoundInfoResponse
     */
    next?: boolean;
}

export function RuntimeRoundInfoResponseFromJSON(json: any): RuntimeRoundInfoResponse {
    return RuntimeRoundInfoResponseFromJSONTyped(json, false);
}

export function RuntimeRoundInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeRoundInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'runtimeId': !exists(json, 'runtimeId') ? undefined : json['runtimeId'],
        'runtimeName': !exists(json, 'runtimeName') ? undefined : json['runtimeName'],
        'round': !exists(json, 'round') ? undefined : json['round'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'header_type': !exists(json, 'header_type') ? undefined : json['header_type'],
        'previous_hash': !exists(json, 'previous_hash') ? undefined : json['previous_hash'],
        'io_root': !exists(json, 'io_root') ? undefined : json['io_root'],
        'state_root': !exists(json, 'state_root') ? undefined : json['state_root'],
        'messages_hash': !exists(json, 'messages_hash') ? undefined : json['messages_hash'],
        'next': !exists(json, 'next') ? undefined : json['next'],
    };
}

export function RuntimeRoundInfoResponseToJSON(value?: RuntimeRoundInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'runtimeId': value.runtimeId,
        'runtimeName': value.runtimeName,
        'round': value.round,
        'timestamp': value.timestamp,
        'header_type': value.header_type,
        'previous_hash': value.previous_hash,
        'io_root': value.io_root,
        'state_root': value.state_root,
        'messages_hash': value.messages_hash,
        'next': value.next,
    };
}


