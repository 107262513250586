/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountRewardInfo
 */
export interface AccountRewardInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountRewardInfo
     */
    validatorAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRewardInfo
     */
    validatorName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRewardInfo
     */
    validatorIcon: string;
    /**
     * 
     * @type {number}
     * @memberof AccountRewardInfo
     */
    epoch: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRewardInfo
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof AccountRewardInfo
     */
    reward: string;
}

export function AccountRewardInfoFromJSON(json: any): AccountRewardInfo {
    return AccountRewardInfoFromJSONTyped(json, false);
}

export function AccountRewardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRewardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validatorAddress': json['validatorAddress'],
        'validatorName': json['validatorName'],
        'validatorIcon': json['validatorIcon'],
        'epoch': json['epoch'],
        'timestamp': json['timestamp'],
        'reward': json['reward'],
    };
}

export function AccountRewardInfoToJSON(value?: AccountRewardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validatorAddress': value.validatorAddress,
        'validatorName': value.validatorName,
        'validatorIcon': value.validatorIcon,
        'epoch': value.epoch,
        'timestamp': value.timestamp,
        'reward': value.reward,
    };
}


