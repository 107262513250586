/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A consensus epoch.
 * @export
 * @interface Epoch
 */
export interface Epoch {
    /**
     * The epoch number.
     * @type {number}
     * @memberof Epoch
     */
    id: number;
    /**
     * The (inclusive) height at which this epoch started.
     * @type {number}
     * @memberof Epoch
     */
    start_height: number;
    /**
     * The (inclusive) height at which this epoch ended. Omitted if the epoch is still active.
     * @type {number}
     * @memberof Epoch
     */
    end_height?: number;
}

export function EpochFromJSON(json: any): Epoch {
    return EpochFromJSONTyped(json, false);
}

export function EpochFromJSONTyped(json: any, ignoreDiscriminator: boolean): Epoch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'start_height': json['start_height'],
        'end_height': !exists(json, 'end_height') ? undefined : json['end_height'],
    };
}

export function EpochToJSON(value?: Epoch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_height': value.start_height,
        'end_height': value.end_height,
    };
}


