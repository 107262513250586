/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TxVolume
 */
export interface TxVolume {
    /**
     * The end timestamp for this daily transaction volume measurement.
     * @type {Date}
     * @memberof TxVolume
     */
    window_end: Date;
    /**
     * The transaction volume for this window.
     * @type {number}
     * @memberof TxVolume
     */
    tx_volume: number;
}

export function TxVolumeFromJSON(json: any): TxVolume {
    return TxVolumeFromJSONTyped(json, false);
}

export function TxVolumeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TxVolume {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'window_end': (new Date(json['window_end'])),
        'tx_volume': json['tx_volume'],
    };
}

export function TxVolumeToJSON(value?: TxVolume | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'window_end': (value.window_end.toISOString()),
        'tx_volume': value.tx_volume,
    };
}


