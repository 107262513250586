/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Balance of an account for a specific runtime and oasis-sdk token (e.g. ROSE).
 * @export
 * @interface RuntimeSdkBalance
 */
export interface RuntimeSdkBalance {
    /**
     * Number of tokens held, in base units.
     * @type {string}
     * @memberof RuntimeSdkBalance
     */
    balance: string;
    /**
     * The token ticker symbol. Unique across all oasis-sdk tokens in the same runtime.
     * @type {string}
     * @memberof RuntimeSdkBalance
     */
    token_symbol: string;
    /**
     * The number of decimals of precision for this token.
     * @type {number}
     * @memberof RuntimeSdkBalance
     */
    token_decimals: number;
}

export function RuntimeSdkBalanceFromJSON(json: any): RuntimeSdkBalance {
    return RuntimeSdkBalanceFromJSONTyped(json, false);
}

export function RuntimeSdkBalanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeSdkBalance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'token_symbol': json['token_symbol'],
        'token_decimals': json['token_decimals'],
    };
}

export function RuntimeSdkBalanceToJSON(value?: RuntimeSdkBalance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance': value.balance,
        'token_symbol': value.token_symbol,
        'token_decimals': value.token_decimals,
    };
}


