"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERR_NO_PROPOSER_COMMITMENT_CODE = exports.ERR_INVALID_ROUND_CODE = exports.ERR_MAJORITY_FAILURE_CODE = exports.ERR_NODE_IS_SCHEDULER_CODE = exports.ERR_TIMEOUT_NOT_CORRECT_ROUND_CODE = exports.ERR_INVALID_MESSAGES_CODE = exports.ERR_BAD_EXECUTOR_COMMITMENT_CODE = exports.ERR_INSUFFICIENT_VOTES_CODE = exports.ERR_STILL_WAITING_CODE = exports.ERR_DISCREPANCY_DETECTED_CODE = exports.ERR_NOT_BASED_ON_CORRECT_BLOCK_CODE = exports.ERR_ALREADY_COMMITTED_CODE = exports.ERR_NOT_IN_COMMITTEE_CODE = exports.ERR_RAK_SIG_INVALID_CODE = exports.ERR_INVALID_COMMITTEE_KIND_CODE = exports.ERR_NO_COMMITTEE_CODE = exports.ERR_NO_RUNTIME_CODE = exports.COMMITMENT_MODULE_NAME = exports.ERR_MAX_IN_MESSAGES_TOO_BIG_CODE = exports.ERR_INCOMING_MESSAGE_INSUFFICIENT_FEE_CODE = exports.ERR_INCOMING_MESSAGE_QUEUE_FULL_CODE = exports.ERR_INVALID_EVIDENCE_CODE = exports.ERR_DUPLICATE_EVIDENCE_CODE = exports.ERR_RUNTIME_DOES_NOT_SLASH_CODE = exports.ERR_MAX_MESSAGES_TOO_BIG_CODE = exports.ERR_PROPOSER_TIMEOUT_NOT_ALLOWED_CODE = exports.ERR_RUNTIME_SUSPENDED_CODE = exports.ERR_NO_EXECUTOR_POOL_CODE = exports.ERR_INVALID_RUNTIME_CODE = exports.ERR_NOT_FOUND_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = exports.FAILURE_STORAGE_UNAVAILABLE = exports.FAILURE_UNKNOWN = exports.FAILURE_NONE = exports.SUSPENDED = exports.EPOCH_TRANSITION = exports.ROUND_FAILED = exports.NORMAL = exports.INVALID = exports.GAS_OP_SUBMIT_MSG = exports.GAS_OP_EVIDENCE = exports.GAS_OP_PROPOSER_TIMEOUT = exports.GAS_OP_COMPUTE_COMMIT = exports.METHOD_SUBMIT_MSG = exports.METHOD_EVIDENCE = exports.METHOD_EXECUTOR_COMMIT = exports.PROPOSAL_SIGNATURE_CONTEXT = exports.COMPUTE_RESULTS_HEADER_SIGNATURE_CONTEXT = exports.EXECUTOR_SIGNATURE_CONTEXT = void 0;
exports.submitMsgWrapper = exports.evidenceWrapper = exports.executorCommitWrapper = exports.signProposal = exports.verifyProposal = exports.signComputeResultsHeader = exports.verifyComputeResultsHeader = exports.signExecutorCommitment = exports.verifyExecutorCommitment = exports.ERR_BAD_PROPOSER_COMMITMENT_CODE = void 0;
const consensus = require("./consensus");
const misc = require("./misc");
const signature = require("./signature");
/**
 * ExecutorSignatureContext is the signature context used to sign executor
 * worker commitments.
 */
exports.EXECUTOR_SIGNATURE_CONTEXT = 'oasis-core/roothash: executor commitment';
/**
 * ComputeResultsHeaderSignatureContext is the signature context used to
 * sign compute results headers with RAK.
 */
exports.COMPUTE_RESULTS_HEADER_SIGNATURE_CONTEXT = 'oasis-core/roothash: compute results header';
/**
 * ProposalSignatureContext is the context used for signing propose batch dispatch messages.
 */
exports.PROPOSAL_SIGNATURE_CONTEXT = 'oasis-core/roothash: proposal';
/**
 * MethodExecutorCommit is the method name for executor commit submission.
 */
exports.METHOD_EXECUTOR_COMMIT = 'roothash.ExecutorCommit';
/**
 * MethodEvidence is the method name for submitting evidence of node misbehavior.
 */
exports.METHOD_EVIDENCE = 'roothash.Evidence';
/**
 * MethodSubmitMsg is the method name for queuing incoming runtime messages.
 */
exports.METHOD_SUBMIT_MSG = 'roothash.SubmitMsg';
/**
 * GasOpComputeCommit is the gas operation identifier for compute commits.
 */
exports.GAS_OP_COMPUTE_COMMIT = 'compute_commit';
/**
 * GasOpProposerTimeout is the gas operation identifier for executor propose timeout cost.
 */
exports.GAS_OP_PROPOSER_TIMEOUT = 'proposer_timeout';
/**
 * GasOpEvidence is the gas operation identifier for evidence submission transaction cost.
 */
exports.GAS_OP_EVIDENCE = 'evidence';
/**
 * GasOpSubmitMsg is the gas operation identifier for message submission transaction cost.
 */
exports.GAS_OP_SUBMIT_MSG = 'submit_msg';
/**
 * Invalid is an invalid header type and should never be stored.
 */
exports.INVALID = 0;
/**
 * Normal is a normal header.
 */
exports.NORMAL = 1;
/**
 * RoundFailed is a header resulting from a failed round. Such a
 * header contains no transactions but advances the round as normal
 * to prevent replays of old commitments.
 */
exports.ROUND_FAILED = 2;
/**
 * EpochTransition is a header resulting from an epoch transition.
 *
 * Such a header contains no transactions but advances the round as
 * normal.
 * TODO: Consider renaming this to CommitteeTransition.
 */
exports.EPOCH_TRANSITION = 3;
/**
 * Suspended is a header resulting from the runtime being suspended.
 *
 * Such a header contains no transactions but advances the round as
 * normal.
 */
exports.SUSPENDED = 4;
/**
 * FailureNone indicates that no failure has occurred.
 */
exports.FAILURE_NONE = 0;
/**
 * FailureUnknown indicates a generic failure.
 */
exports.FAILURE_UNKNOWN = 1;
/**
 * FailureStorageUnavailable indicates that batch processing failed due to
 * storage being unavailable.
 */
exports.FAILURE_STORAGE_UNAVAILABLE = 2;
/**
 * ModuleName is a unique module name for the roothash module.
 */
exports.MODULE_NAME = 'roothash';
/**
 * ErrInvalidArgument is the error returned on malformed argument(s).
 */
exports.ERR_INVALID_ARGUMENT_CODE = 1;
/**
 * ErrNotFound is the error returned when a block is not found.
 */
exports.ERR_NOT_FOUND_CODE = 2;
/**
 * ErrInvalidRuntime is the error returned when the passed runtime is invalid.
 */
exports.ERR_INVALID_RUNTIME_CODE = 3;
/**
 * ErrNoExecutorPool is the error returned when there is no executor pool.
 */
exports.ERR_NO_EXECUTOR_POOL_CODE = 4;
/**
 * ErrRuntimeSuspended is the error returned when the passed runtime is suspended.
 */
exports.ERR_RUNTIME_SUSPENDED_CODE = 5;
/**
 * ErrProposerTimeoutNotAllowed is the error returned when proposer timeout is not allowed.
 */
exports.ERR_PROPOSER_TIMEOUT_NOT_ALLOWED_CODE = 6;
/**
 * ErrMaxMessagesTooBig is the error returned when the MaxMessages parameter is set to a value
 * larger than the MaxRuntimeMessages specified in consensus parameters.
 */
exports.ERR_MAX_MESSAGES_TOO_BIG_CODE = 7;
/**
 * ErrRuntimeDoesNotSlash is the error returned when misbehaviour evidence is submitted for a
 * runtime that does not slash.
 */
exports.ERR_RUNTIME_DOES_NOT_SLASH_CODE = 8;
/**
 * ErrDuplicateEvidence is the error returned when submitting already existing evidence.
 */
exports.ERR_DUPLICATE_EVIDENCE_CODE = 9;
/**
 * ErrInvalidEvidence is the error returned when an invalid evidence is submitted.
 */
exports.ERR_INVALID_EVIDENCE_CODE = 10;
/**
 * ErrIncomingMessageQueueFull is the error returned when the incoming message queue is full.
 */
exports.ERR_INCOMING_MESSAGE_QUEUE_FULL_CODE = 11;
/**
 * ErrIncomingMessageInsufficientFee is the error returned when the provided fee is smaller than
 * the configured minimum incoming message submission fee.
 */
exports.ERR_INCOMING_MESSAGE_INSUFFICIENT_FEE_CODE = 12;
/**
 * ErrMaxInMessagesTooBig is the error returned when the MaxInMessages parameter is set to a
 * value larger than the MaxInRuntimeMessages specified in consensus parameters.
 */
exports.ERR_MAX_IN_MESSAGES_TOO_BIG_CODE = 13;
/**
 * moduleName is the module name used for namespacing errors.
 */
exports.COMMITMENT_MODULE_NAME = 'roothash/commitment';
exports.ERR_NO_RUNTIME_CODE = 1;
exports.ERR_NO_COMMITTEE_CODE = 2;
exports.ERR_INVALID_COMMITTEE_KIND_CODE = 3;
exports.ERR_RAK_SIG_INVALID_CODE = 4;
exports.ERR_NOT_IN_COMMITTEE_CODE = 5;
exports.ERR_ALREADY_COMMITTED_CODE = 6;
exports.ERR_NOT_BASED_ON_CORRECT_BLOCK_CODE = 7;
exports.ERR_DISCREPANCY_DETECTED_CODE = 8;
exports.ERR_STILL_WAITING_CODE = 9;
exports.ERR_INSUFFICIENT_VOTES_CODE = 10;
exports.ERR_BAD_EXECUTOR_COMMITMENT_CODE = 11;
exports.ERR_INVALID_MESSAGES_CODE = 13;
exports.ERR_TIMEOUT_NOT_CORRECT_ROUND_CODE = 15;
exports.ERR_NODE_IS_SCHEDULER_CODE = 16;
exports.ERR_MAJORITY_FAILURE_CODE = 17;
exports.ERR_INVALID_ROUND_CODE = 18;
exports.ERR_NO_PROPOSER_COMMITMENT_CODE = 19;
exports.ERR_BAD_PROPOSER_COMMITMENT_CODE = 20;
async function verifyExecutorCommitment(chainContext, runtimeID, commitment) {
    const context = `${signature.combineChainContext(exports.EXECUTOR_SIGNATURE_CONTEXT, chainContext)} for runtime ${misc.toHex(runtimeID)}`;
    return await signature.verify(commitment.node_id, context, misc.toCBOR(commitment.header), commitment.sig);
}
exports.verifyExecutorCommitment = verifyExecutorCommitment;
async function signExecutorCommitment(signer, chainContext, runtimeID, header) {
    const context = `${signature.combineChainContext(exports.EXECUTOR_SIGNATURE_CONTEXT, chainContext)} for runtime ${misc.toHex(runtimeID)}`;
    return await signer.sign(context, misc.toCBOR(header));
}
exports.signExecutorCommitment = signExecutorCommitment;
async function verifyComputeResultsHeader(rakPub, header, rakSig) {
    return await signature.verify(rakPub, exports.COMPUTE_RESULTS_HEADER_SIGNATURE_CONTEXT, misc.toCBOR(header), rakSig);
}
exports.verifyComputeResultsHeader = verifyComputeResultsHeader;
async function signComputeResultsHeader(rakSigner, header) {
    return await rakSigner.sign(exports.COMPUTE_RESULTS_HEADER_SIGNATURE_CONTEXT, misc.toCBOR(header));
}
exports.signComputeResultsHeader = signComputeResultsHeader;
async function verifyProposal(chainContext, runtimeID, proposal) {
    const context = `${signature.combineChainContext(exports.PROPOSAL_SIGNATURE_CONTEXT, chainContext)} for runtime ${misc.toHex(runtimeID)}`;
    return await signature.verify(proposal.node_id, context, misc.toCBOR(proposal.header), proposal.sig);
}
exports.verifyProposal = verifyProposal;
async function signProposal(signer, chainContext, runtimeID, header) {
    const context = `${signature.combineChainContext(exports.PROPOSAL_SIGNATURE_CONTEXT, chainContext)} for runtime ${misc.toHex(runtimeID)}`;
    return await signer.sign(context, misc.toCBOR(header));
}
exports.signProposal = signProposal;
function executorCommitWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_EXECUTOR_COMMIT);
}
exports.executorCommitWrapper = executorCommitWrapper;
function evidenceWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_EVIDENCE);
}
exports.evidenceWrapper = evidenceWrapper;
function submitMsgWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_SUBMIT_MSG);
}
exports.submitMsgWrapper = submitMsgWrapper;
