/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Node,
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './';

/**
 * A list of nodes registered at the consensus layer.
 * @export
 * @interface NodeListAllOf
 */
export interface NodeListAllOf {
    /**
     * 
     * @type {string}
     * @memberof NodeListAllOf
     */
    entity_id: string;
    /**
     * 
     * @type {Array<Node>}
     * @memberof NodeListAllOf
     */
    nodes: Array<Node>;
}

export function NodeListAllOfFromJSON(json: any): NodeListAllOf {
    return NodeListAllOfFromJSONTyped(json, false);
}

export function NodeListAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeListAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'nodes': ((json['nodes'] as Array<any>).map(NodeFromJSON)),
    };
}

export function NodeListAllOfToJSON(value?: NodeListAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'nodes': ((value.nodes as Array<any>).map(NodeToJSON)),
    };
}


