"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Visitor = exports.toKey = void 0;
const oasis = require("@oasisprotocol/client");
function toKey(module, code) {
    const codeBuf = new ArrayBuffer(4);
    const codeDV = new DataView(codeBuf);
    codeDV.setUint32(0, code, false);
    return oasis.misc.concat(oasis.misc.fromString(module), new Uint8Array(codeBuf));
}
exports.toKey = toKey;
class Visitor {
    constructor(modules) {
        this.handlers = {};
        for (const [module, codes] of modules) {
            for (const code in codes) {
                this.handlers[oasis.misc.toHex(toKey(module, +code))] = codes[code];
            }
        }
    }
    /**
     * Calls one of the handlers based on a given event's key.
     * @param e The event
     * @returns `true` if the event matched one of the handlers
     */
    visit(e) {
        const keyHex = oasis.misc.toHex(e.key);
        if (this.handlers[keyHex]) {
            const values = oasis.misc.fromCBOR(e.value);
            for (const value of values) {
                this.handlers[keyHex](e, value);
            }
            return true;
        }
        return false;
    }
}
exports.Visitor = Visitor;
