"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromBech32 = exports.toBech32 = exports.fromData = void 0;
const bech32_1 = require("bech32");
const hash = require("./hash");
const misc = require("./misc");
async function fromData(contextIdentifier, contextVersion, data) {
    const versionU8 = new Uint8Array([contextVersion]);
    return misc.concat(versionU8, (await hash.hash(misc.concat(misc.fromString(contextIdentifier), versionU8, data))).slice(0, 20));
}
exports.fromData = fromData;
function toBech32(prefix, addr) {
    return bech32_1.bech32.encode(prefix, bech32_1.bech32.toWords(addr));
}
exports.toBech32 = toBech32;
function fromBech32(expectedPrefix, str) {
    const { prefix, words } = bech32_1.bech32.decode(str);
    if (prefix !== expectedPrefix) {
        throw new Error(`wrong prefix: ${prefix}, expected ${expectedPrefix}`);
    }
    return new Uint8Array(bech32_1.bech32.fromWords(words));
}
exports.fromBech32 = fromBech32;
