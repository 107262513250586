"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleEventHandler = exports.Wrapper = exports.EVENT_MINT_CODE = exports.EVENT_BURN_CODE = exports.EVENT_TRANSFER_CODE = exports.METHOD_DENOMINATION_INFO = exports.METHOD_ADDRESSES = exports.METHOD_BALANCES = exports.METHOD_NONCE = exports.METHOD_TRANSFER = exports.ERR_NOT_FOUND_CODE = exports.ERR_FORBIDDEN_CODE = exports.ERR_INSUFFICIENT_BALANCE_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'accounts';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
exports.ERR_INSUFFICIENT_BALANCE_CODE = 2;
exports.ERR_FORBIDDEN_CODE = 3;
exports.ERR_NOT_FOUND_CODE = 4;
// Callable methods.
exports.METHOD_TRANSFER = 'accounts.Transfer';
// Queries.
exports.METHOD_NONCE = 'accounts.Nonce';
exports.METHOD_BALANCES = 'accounts.Balances';
exports.METHOD_ADDRESSES = 'accounts.Addresses';
exports.METHOD_DENOMINATION_INFO = 'accounts.DenominationInfo';
exports.EVENT_TRANSFER_CODE = 1;
exports.EVENT_BURN_CODE = 2;
exports.EVENT_MINT_CODE = 3;
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    callTransfer() {
        return this.call(exports.METHOD_TRANSFER);
    }
    queryNonce() {
        return this.query(exports.METHOD_NONCE);
    }
    queryBalances() {
        return this.query(exports.METHOD_BALANCES);
    }
    queryAddresses() {
        return this.query(exports.METHOD_ADDRESSES);
    }
    queryDenominationInfo() {
        return this.query(exports.METHOD_DENOMINATION_INFO);
    }
}
exports.Wrapper = Wrapper;
function moduleEventHandler(codes) {
    return [exports.MODULE_NAME, codes];
}
exports.moduleEventHandler = moduleEventHandler;
