/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bound
 */
export interface Bound {
    /**
     * 
     * @type {number}
     * @memberof Bound
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof Bound
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof Bound
     */
    max: number;
}

export function BoundFromJSON(json: any): Bound {
    return BoundFromJSONTyped(json, false);
}

export function BoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'min': json['min'],
        'max': json['max'],
    };
}

export function BoundToJSON(value?: Bound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'min': value.min,
        'max': value.max,
    };
}


