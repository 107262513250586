/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorEntity
 */
export interface ValidatorEntity {
    /**
     * 
     * @type {string}
     * @memberof ValidatorEntity
     */
    account_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatorEntity
     */
    account_name?: string;
}

export function ValidatorEntityFromJSON(json: any): ValidatorEntity {
    return ValidatorEntityFromJSONTyped(json, false);
}

export function ValidatorEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
    };
}

export function ValidatorEntityToJSON(value?: ValidatorEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'account_name': value.account_name,
    };
}


