/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValidatorInfo,
    ValidatorInfoFromJSON,
    ValidatorInfoFromJSONTyped,
    ValidatorInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountsRow
 */
export interface AccountsRow {
    /**
     * 
     * @type {string}
     * @memberof AccountsRow
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    liquid_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    escrow_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    escrow_debonding_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    total_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    delegations_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    debonding_delegations_balance: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    self_delegation_balance: number;
    /**
     * 
     * @type {string}
     * @memberof AccountsRow
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountsRow
     */
    last_active?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountsRow
     */
    nonce?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountsRow
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountsRow
     */
    entity_address?: string;
    /**
     * 
     * @type {ValidatorInfo}
     * @memberof AccountsRow
     */
    validator?: ValidatorInfo;
}

export function AccountsRowFromJSON(json: any): AccountsRow {
    return AccountsRowFromJSONTyped(json, false);
}

export function AccountsRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountsRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'liquid_balance': json['liquid_balance'],
        'escrow_balance': json['escrow_balance'],
        'escrow_debonding_balance': json['escrow_debonding_balance'],
        'total_balance': json['total_balance'],
        'delegations_balance': json['delegations_balance'],
        'debonding_delegations_balance': json['debonding_delegations_balance'],
        'self_delegation_balance': json['self_delegation_balance'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'last_active': !exists(json, 'last_active') ? undefined : json['last_active'],
        'nonce': !exists(json, 'nonce') ? undefined : json['nonce'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'entity_address': !exists(json, 'entity_address') ? undefined : json['entity_address'],
        'validator': !exists(json, 'validator') ? undefined : ValidatorInfoFromJSON(json['validator']),
    };
}

export function AccountsRowToJSON(value?: AccountsRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'liquid_balance': value.liquid_balance,
        'escrow_balance': value.escrow_balance,
        'escrow_debonding_balance': value.escrow_debonding_balance,
        'total_balance': value.total_balance,
        'delegations_balance': value.delegations_balance,
        'debonding_delegations_balance': value.debonding_delegations_balance,
        'self_delegation_balance': value.self_delegation_balance,
        'created_at': value.created_at,
        'last_active': value.last_active,
        'nonce': value.nonce,
        'type': value.type,
        'entity_address': value.entity_address,
        'validator': ValidatorInfoToJSON(value.validator),
    };
}


