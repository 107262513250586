/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountRewardsStat
 */
export interface AccountRewardsStat {
    /**
     * 
     * @type {string}
     * @memberof AccountRewardsStat
     */
    account_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRewardsStat
     */
    total_reward?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountRewardsStat
     */
    day_reward?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRewardsStat
     */
    week_reward?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRewardsStat
     */
    month_reward?: number;
}

export function AccountRewardsStatFromJSON(json: any): AccountRewardsStat {
    return AccountRewardsStatFromJSONTyped(json, false);
}

export function AccountRewardsStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRewardsStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': !exists(json, 'account_id') ? undefined : json['account_id'],
        'total_reward': !exists(json, 'total_reward') ? undefined : json['total_reward'],
        'day_reward': !exists(json, 'day_reward') ? undefined : json['day_reward'],
        'week_reward': !exists(json, 'week_reward') ? undefined : json['week_reward'],
        'month_reward': !exists(json, 'month_reward') ? undefined : json['month_reward'],
    };
}

export function AccountRewardsStatToJSON(value?: AccountRewardsStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'total_reward': value.total_reward,
        'day_reward': value.day_reward,
        'week_reward': value.week_reward,
        'month_reward': value.month_reward,
    };
}


