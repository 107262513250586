"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeResult = exports.encodeCall = exports.encodeCallWithNonceAndKeys = exports.CALL_DATA_KEY_PAIR_ID_CONTEXT_BASE = void 0;
const oasis = require("@oasisprotocol/client");
const deoxysii = require("@oasisprotocol/deoxysii");
const nacl = require("tweetnacl");
const randomBytes = require("randombytes");
const mraeDeoxysii = require("./mrae/deoxysii");
const transaction = require("./transaction");
/**
 * Call data key pair ID domain separation context base.
 */
exports.CALL_DATA_KEY_PAIR_ID_CONTEXT_BASE = 'oasis-runtime-sdk/private: tx';
/**
 * encodeCallWithNonceAndKeys encodes a call based on its configured call format.
 * It returns the encoded call and any metadata needed to successfully decode the result.
 */
async function encodeCallWithNonceAndKeys(nonce, sk, pk, call, format, config) {
    switch (format) {
        case transaction.CALLFORMAT_PLAIN:
            return [call, undefined];
        case transaction.CALLFORMAT_ENCRYPTED_X25519DEOXYSII:
            if (config?.publicKey === undefined) {
                throw new Error('callformat: runtime call data public key not set');
            }
            const rawCall = oasis.misc.toCBOR(call);
            const zeroBuffer = new Uint8Array(0);
            const sealedCall = mraeDeoxysii.boxSeal(nonce, rawCall, zeroBuffer, config.publicKey.key, sk);
            const envelope = {
                pk: pk,
                nonce: nonce,
                data: sealedCall,
            };
            const encoded = {
                format: transaction.CALLFORMAT_ENCRYPTED_X25519DEOXYSII,
                method: '',
                body: oasis.misc.toCBOR(envelope),
            };
            const meta = {
                sk: sk,
                pk: config.publicKey.key,
            };
            return [encoded, meta];
        default:
            throw new Error(`callformat: unsupported call format: ${format}`);
    }
}
exports.encodeCallWithNonceAndKeys = encodeCallWithNonceAndKeys;
/**
 * encodeCall randomly generates nonce and keyPair and then call encodeCallWithNonceAndKeys
 * It returns the encoded call and any metadata needed to successfully decode the result.
 */
async function encodeCall(call, format, config) {
    const nonce = randomBytes(deoxysii.NonceSize);
    const keyPair = nacl.box.keyPair();
    return await encodeCallWithNonceAndKeys(nonce, keyPair.secretKey, keyPair.publicKey, call, format, config);
}
exports.encodeCall = encodeCall;
/**
 * decodeResult performs result decoding based on the specified call format metadata.
 */
async function decodeResult(result, format, meta) {
    switch (format) {
        case transaction.CALLFORMAT_PLAIN:
            // In case of plain-text data format, we simply pass on the result unchanged.
            return result;
        case transaction.CALLFORMAT_ENCRYPTED_X25519DEOXYSII:
            if (result.unknown) {
                if (meta) {
                    const envelop = oasis.misc.fromCBOR(result.unknown);
                    const zeroBuffer = new Uint8Array(0);
                    const pt = mraeDeoxysii.boxOpen(envelop?.nonce, envelop?.data, zeroBuffer, meta.pk, meta.sk);
                    return oasis.misc.fromCBOR(pt);
                }
                else {
                    throw new Error(`callformat: MetaEncryptedX25519DeoxysII data is required for callformat: CALLFORMAT_ENCRYPTED_X25519DEOXYSII`);
                }
            }
            else if (result.fail) {
                throw new Error(`callformat: failed call: module: ${result.fail.module} code: ${result.fail.code} message: ${result.fail.message}`);
            }
            throw Object.assign(new Error(`callformat: unexpected result: ${result.ok}`), {
                source: result,
            });
        default:
            throw new Error(`callformat: unsupported call format: ${format}`);
    }
}
exports.decodeResult = decodeResult;
