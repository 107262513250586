"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerRuntimeWrapper = exports.unfreezeNodeWrapper = exports.registerNodeWrapper = exports.deregisterEntityWrapper = exports.registerEntityWrapper = exports.ERR_ENTITY_HAS_RUNTIMES_CODE = exports.ERR_RUNTIME_UPDATE_NOT_ALLOWED_CODE = exports.ERR_NODE_UPDATE_NOT_ALLOWED_CODE = exports.ERR_FORBIDDEN_CODE = exports.ERR_ENTITY_HAS_NODES_CODE = exports.ERR_NODE_CANNOT_BE_UNFROZEN_CODE = exports.ERR_NODE_EXPIRED_CODE = exports.ERR_INCORRECT_TX_SIGNER_CODE = exports.ERR_NO_SUCH_RUNTIME_CODE = exports.ERR_NO_SUCH_NODE_CODE = exports.ERR_NO_SUCH_ENTITY_CODE = exports.ERR_TEE_HARDWARE_MISMATCH_CODE = exports.ERR_BAD_CAPABILITIES_TEE_HARDWARE_CODE = exports.ERR_BAD_ENCLAVE_IDENTITY_CODE = exports.ERR_NO_ENCLAVE_FOR_RUNTIME_CODE = exports.ERR_BAD_ENTITY_FOR_RUNTIME_CODE = exports.ERR_BAD_ENTITY_FOR_NODE_CODE = exports.ERR_INVALID_SIGNATURE_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = exports.LATEST_RUNTIME_DESCRIPTOR_VERSION = exports.GOVERNANCE_MAX = exports.GOVERNANCE_CONSENSUS = exports.GOVERNANCE_RUNTIME = exports.GOVERNANCE_ENTITY = exports.GOVERNANCE_INVALID = exports.KIND_KEY_MANAGER = exports.KIND_COMPUTE = exports.KIND_INVALID = exports.GAS_OP_UPDATEKEY_MANAGER = exports.GAS_OP_RUNTIME_EPOCH_MAINTENANCE = exports.GAS_OP_REGISTER_RUNTIME = exports.GAS_OP_UNFREEZE_NODE = exports.GAS_OP_REGISTER_NODE = exports.GAS_OP_DEREGISTER_ENTITY = exports.GAS_OP_REGISTER_ENTITY = exports.METHOD_REGISTER_RUNTIME = exports.METHOD_UNFREEZE_NODE = exports.METHOD_REGISTER_NODE = exports.METHOD_DEREGISTER_ENTITY = exports.METHOD_REGISTER_ENTITY = exports.REGISTER_GENESIS_NODE_SIGNATURE_CONTEXT = exports.REGISTER_NODE_SIGNATURE_CONTEXT = exports.REGISTER_GENESIS_ENTITY_SIGNATURE_CONTEXT = exports.REGISTER_ENTITY_SIGNATURE_CONTEXT = void 0;
const consensus = require("./consensus");
/**
 * RegisterEntitySignatureContext is the context used for entity
 * registration.
 */
exports.REGISTER_ENTITY_SIGNATURE_CONTEXT = 'oasis-core/registry: register entity';
/**
 * RegisterGenesisEntitySignatureContext is the context used for
 * entity registration in the genesis document.
 *
 * Note: This is identical to non-gensis registrations to support
 * migrating existing registrations into a new genesis document.
 */
exports.REGISTER_GENESIS_ENTITY_SIGNATURE_CONTEXT = exports.REGISTER_ENTITY_SIGNATURE_CONTEXT;
/**
 * RegisterNodeSignatureContext is the context used for node
 * registration.
 */
exports.REGISTER_NODE_SIGNATURE_CONTEXT = 'oasis-core/registry: register node';
/**
 * RegisterGenesisNodeSignatureContext is the context used for
 * node registration in the genesis document.
 *
 * Note: This is identical to non-gensis registrations to support
 * migrating existing registrations into a new genesis document.
 */
exports.REGISTER_GENESIS_NODE_SIGNATURE_CONTEXT = exports.REGISTER_NODE_SIGNATURE_CONTEXT;
/**
 * MethodRegisterEntity is the method name for entity registrations.
 */
exports.METHOD_REGISTER_ENTITY = 'registry.RegisterEntity';
/**
 * MethodDeregisterEntity is the method name for entity deregistrations.
 */
exports.METHOD_DEREGISTER_ENTITY = 'registry.DeregisterEntity';
/**
 * MethodRegisterNode is the method name for node registrations.
 */
exports.METHOD_REGISTER_NODE = 'registry.RegisterNode';
/**
 * MethodUnfreezeNode is the method name for unfreezing nodes.
 */
exports.METHOD_UNFREEZE_NODE = 'registry.UnfreezeNode';
/**
 * MethodRegisterRuntime is the method name for registering runtimes.
 */
exports.METHOD_REGISTER_RUNTIME = 'registry.RegisterRuntime';
/**
 * GasOpRegisterEntity is the gas operation identifier for entity registration.
 */
exports.GAS_OP_REGISTER_ENTITY = 'register_entity';
/**
 * GasOpDeregisterEntity is the gas operation identifier for entity deregistration.
 */
exports.GAS_OP_DEREGISTER_ENTITY = 'deregister_entity';
/**
 * GasOpRegisterNode is the gas operation identifier for entity registration.
 */
exports.GAS_OP_REGISTER_NODE = 'register_node';
/**
 * GasOpUnfreezeNode is the gas operation identifier for unfreezing nodes.
 */
exports.GAS_OP_UNFREEZE_NODE = 'unfreeze_node';
/**
 * GasOpRegisterRuntime is the gas operation identifier for runtime registration.
 */
exports.GAS_OP_REGISTER_RUNTIME = 'register_runtime';
/**
 * GasOpRuntimeEpochMaintenance is the gas operation identifier for per-epoch
 * runtime maintenance costs.
 */
exports.GAS_OP_RUNTIME_EPOCH_MAINTENANCE = 'runtime_epoch_maintenance';
/**
 * GasOpUpdateKeyManager is the gas operation identifier for key manager
 * policy updates costs.
 */
exports.GAS_OP_UPDATEKEY_MANAGER = 'update_keymanager';
/**
 * KindInvalid is an invalid runtime and should never be explicitly set.
 */
exports.KIND_INVALID = 0;
/**
 * KindCompute is a generic compute runtime.
 */
exports.KIND_COMPUTE = 1;
/**
 * KindKeyManager is a key manager runtime.
 */
exports.KIND_KEY_MANAGER = 2;
exports.GOVERNANCE_INVALID = 0;
exports.GOVERNANCE_ENTITY = 1;
exports.GOVERNANCE_RUNTIME = 2;
exports.GOVERNANCE_CONSENSUS = 3;
exports.GOVERNANCE_MAX = exports.GOVERNANCE_CONSENSUS;
/**
 * LatestRuntimeDescriptorVersion is the latest entity descriptor version that should be used
 * for all new descriptors. Using earlier versions may be rejected.
 */
exports.LATEST_RUNTIME_DESCRIPTOR_VERSION = 3;
/**
 * ModuleName is a unique module name for the registry module.
 */
exports.MODULE_NAME = 'registry';
/**
 * ErrInvalidArgument is the error returned on malformed argument(s).
 */
exports.ERR_INVALID_ARGUMENT_CODE = 1;
/**
 * ErrInvalidSignature is the error returned on an invalid signature.
 */
exports.ERR_INVALID_SIGNATURE_CODE = 2;
/**
 * ErrBadEntityForNode is the error returned when a node registration
 * with an unknown entity is attempted.
 */
exports.ERR_BAD_ENTITY_FOR_NODE_CODE = 3;
/**
 * ErrBadEntityForRuntime is the error returned when a runtime
 * attempts to register with an unknown entity.
 */
exports.ERR_BAD_ENTITY_FOR_RUNTIME_CODE = 4;
/**
 * ErrNoEnclaveForRuntime is the error returned when a TEE runtime
 * registers with no enclave IDs.
 */
exports.ERR_NO_ENCLAVE_FOR_RUNTIME_CODE = 5;
/**
 * ErrBadEnclaveIdentity is the error returned when a node tries to
 * register runtimes with wrong Enclave IDs.
 */
exports.ERR_BAD_ENCLAVE_IDENTITY_CODE = 6;
/**
 * ErrBadCapabilitiesTEEHardware is the error returned when a node tries to
 * register a runtime with bad Capabilities.TEE.Hardware.
 */
exports.ERR_BAD_CAPABILITIES_TEE_HARDWARE_CODE = 7;
/**
 * ErrTEEHardwareMismatch is the error returned when a node tries to
 * register a runtime and Capabilities.TEE.Hardware mismatches the one in
 * the registry.
 */
exports.ERR_TEE_HARDWARE_MISMATCH_CODE = 8;
/**
 * ErrNoSuchEntity is the error returned when an entity does not exist.
 */
exports.ERR_NO_SUCH_ENTITY_CODE = 9;
/**
 * ErrNoSuchNode is the error returned when an node does not exist.
 */
exports.ERR_NO_SUCH_NODE_CODE = 10;
/**
 * ErrNoSuchRuntime is the error returned when an runtime does not exist.
 */
exports.ERR_NO_SUCH_RUNTIME_CODE = 11;
/**
 * ErrIncorrectTxSigner is the error returned when the signer of the transaction
 * is not the correct one.
 */
exports.ERR_INCORRECT_TX_SIGNER_CODE = 12;
/**
 * ErrNodeExpired is the error returned when a node is expired.
 */
exports.ERR_NODE_EXPIRED_CODE = 13;
/**
 * ErrNodeCannotBeUnfrozen is the error returned when a node cannot yet be
 * unfrozen due to the freeze period not being over yet.
 */
exports.ERR_NODE_CANNOT_BE_UNFROZEN_CODE = 14;
/**
 * ErrEntityHasNodes is the error returned when an entity cannot be deregistered
 * as it still has nodes.
 */
exports.ERR_ENTITY_HAS_NODES_CODE = 15;
/**
 * ErrForbidden is the error returned when an operation is forbidden by
 * policy.
 */
exports.ERR_FORBIDDEN_CODE = 16;
/**
 * ErrNodeUpdateNotAllowed is the error returned when trying to update an existing node with
 * disallowed changes.
 */
exports.ERR_NODE_UPDATE_NOT_ALLOWED_CODE = 17;
/**
 * ErrRuntimeUpdateNotAllowed is the error returned when trying to update an existing runtime.
 */
exports.ERR_RUNTIME_UPDATE_NOT_ALLOWED_CODE = 18;
/**
 * ErrEntityHasRuntimes is the error returned when an entity cannot be deregistered as it still
 * has runtimes.
 */
exports.ERR_ENTITY_HAS_RUNTIMES_CODE = 19;
function registerEntityWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_REGISTER_ENTITY);
}
exports.registerEntityWrapper = registerEntityWrapper;
function deregisterEntityWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_DEREGISTER_ENTITY);
}
exports.deregisterEntityWrapper = deregisterEntityWrapper;
function registerNodeWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_REGISTER_NODE);
}
exports.registerNodeWrapper = registerNodeWrapper;
function unfreezeNodeWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_UNFREEZE_NODE);
}
exports.unfreezeNodeWrapper = unfreezeNodeWrapper;
function registerRuntimeWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_REGISTER_RUNTIME);
}
exports.registerRuntimeWrapper = registerRuntimeWrapper;
