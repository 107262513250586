/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TxVolume,
    TxVolumeFromJSON,
    TxVolumeFromJSONTyped,
    TxVolumeToJSON,
} from './';

/**
 * A list of daily transaction volumes.
 * @export
 * @interface TxVolumeList
 */
export interface TxVolumeList {
    /**
     * 
     * @type {number}
     * @memberof TxVolumeList
     */
    window_size_seconds: number;
    /**
     * The list of daily transaction volumes.
     * @type {Array<TxVolume>}
     * @memberof TxVolumeList
     */
    windows: Array<TxVolume>;
}

export function TxVolumeListFromJSON(json: any): TxVolumeList {
    return TxVolumeListFromJSONTyped(json, false);
}

export function TxVolumeListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TxVolumeList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'window_size_seconds': json['window_size_seconds'],
        'windows': ((json['windows'] as Array<any>).map(TxVolumeFromJSON)),
    };
}

export function TxVolumeListToJSON(value?: TxVolumeList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'window_size_seconds': value.window_size_seconds,
        'windows': ((value.windows as Array<any>).map(TxVolumeToJSON)),
    };
}


