/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountStakingEventsInfoResponse
 */
export interface AccountStakingEventsInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof AccountStakingEventsInfoResponse
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof AccountStakingEventsInfoResponse
     */
    txHash: string;
    /**
     * 
     * @type {string}
     * @memberof AccountStakingEventsInfoResponse
     */
    kind: string;
    /**
     * 
     * @type {number}
     * @memberof AccountStakingEventsInfoResponse
     */
    timestamp: number;
    /**
     * 
     * @type {object}
     * @memberof AccountStakingEventsInfoResponse
     */
    transafer?: object;
    /**
     * 
     * @type {object}
     * @memberof AccountStakingEventsInfoResponse
     */
    burn?: object;
    /**
     * 
     * @type {object}
     * @memberof AccountStakingEventsInfoResponse
     */
    escrow?: object;
    /**
     * 
     * @type {object}
     * @memberof AccountStakingEventsInfoResponse
     */
    allowanceChange?: object;
}

export function AccountStakingEventsInfoResponseFromJSON(json: any): AccountStakingEventsInfoResponse {
    return AccountStakingEventsInfoResponseFromJSONTyped(json, false);
}

export function AccountStakingEventsInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountStakingEventsInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': json['height'],
        'txHash': json['txHash'],
        'kind': json['kind'],
        'timestamp': json['timestamp'],
        'transafer': !exists(json, 'transafer') ? undefined : json['transafer'],
        'burn': !exists(json, 'burn') ? undefined : json['burn'],
        'escrow': !exists(json, 'escrow') ? undefined : json['escrow'],
        'allowanceChange': !exists(json, 'allowanceChange') ? undefined : json['allowanceChange'],
    };
}

export function AccountStakingEventsInfoResponseToJSON(value?: AccountStakingEventsInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': value.height,
        'txHash': value.txHash,
        'kind': value.kind,
        'timestamp': value.timestamp,
        'transafer': value.transafer,
        'burn': value.burn,
        'escrow': value.escrow,
        'allowanceChange': value.allowanceChange,
    };
}


