/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Chart
 */
export interface Chart {
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Chart
     */
    value: string;
}

export function ChartFromJSON(json: any): Chart {
    return ChartFromJSONTyped(json, false);
}

export function ChartFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function ChartToJSON(value?: Chart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}


