"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEBUG_ERR_INCOMPATIBLE_BACKEND_CODE = exports.DEBUG_MODULE_NAME = void 0;
/**
 * DebugModuleName is the module name for the debug controller service.
 */
exports.DEBUG_MODULE_NAME = 'control/debug';
/**
 * ErrIncompatibleBackend is the error raised when the current beacon
 * backend does not support manually setting the current epoch.
 */
exports.DEBUG_ERR_INCOMPATIBLE_BACKEND_CODE = 1;
