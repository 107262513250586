/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValidatorHistoryPoint,
    ValidatorHistoryPointFromJSON,
    ValidatorHistoryPointFromJSONTyped,
    ValidatorHistoryPointToJSON,
} from './';

/**
 * Historical escrow balance data for a single address.
 * @export
 * @interface ValidatorHistoryAllOf
 */
export interface ValidatorHistoryAllOf {
    /**
     * The staking address of the validator.
     * @type {string}
     * @memberof ValidatorHistoryAllOf
     */
    address?: string;
    /**
     * 
     * @type {Array<ValidatorHistoryPoint>}
     * @memberof ValidatorHistoryAllOf
     */
    history: Array<ValidatorHistoryPoint>;
}

export function ValidatorHistoryAllOfFromJSON(json: any): ValidatorHistoryAllOf {
    return ValidatorHistoryAllOfFromJSONTyped(json, false);
}

export function ValidatorHistoryAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorHistoryAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'history': ((json['history'] as Array<any>).map(ValidatorHistoryPointFromJSON)),
    };
}

export function ValidatorHistoryAllOfToJSON(value?: ValidatorHistoryAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'history': ((value.history as Array<any>).map(ValidatorHistoryPointToJSON)),
    };
}


