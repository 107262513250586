/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorSignStatsInfo
 */
export interface ValidatorSignStatsInfo {
    /**
     * 
     * @type {number}
     * @memberof ValidatorSignStatsInfo
     */
    dateTime: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorSignStatsInfo
     */
    expected: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorSignStatsInfo
     */
    actual: number;
}

export function ValidatorSignStatsInfoFromJSON(json: any): ValidatorSignStatsInfo {
    return ValidatorSignStatsInfoFromJSONTyped(json, false);
}

export function ValidatorSignStatsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorSignStatsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTime': json['dateTime'],
        'expected': json['expected'],
        'actual': json['actual'],
    };
}

export function ValidatorSignStatsInfoToJSON(value?: ValidatorSignStatsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTime': value.dateTime,
        'expected': value.expected,
        'actual': value.actual,
    };
}


