/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MarketChartResponse,
    MarketChartResponseFromJSON,
    MarketChartResponseToJSON,
    MarketInfoResponse,
    MarketInfoResponseFromJSON,
    MarketInfoResponseToJSON,
} from '../models';

/**
 * 
 */
export class MarketApi extends runtime.BaseAPI {

    /**
     */
    async marketChartHandlerRaw(): Promise<runtime.ApiResponse<MarketChartResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/market/chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketChartResponseFromJSON(jsonValue));
    }

    /**
     */
    async marketChartHandler(): Promise<MarketChartResponse> {
        const response = await this.marketChartHandlerRaw();
        return await response.value();
    }

    /**
     */
    async marketInfoHandlerRaw(): Promise<runtime.ApiResponse<MarketInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/market/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async marketInfoHandler(): Promise<MarketInfoResponse> {
        const response = await this.marketInfoHandlerRaw();
        return await response.value();
    }

}
