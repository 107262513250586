/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NetworkStatusResponse
 */
export interface NetworkStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof NetworkStatusResponse
     */
    curHeight: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkStatusResponse
     */
    curEpoch: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkStatusResponse
     */
    totalTxs: number;
    /**
     * 
     * @type {string}
     * @memberof NetworkStatusResponse
     */
    totalEscrow: string;
    /**
     * 
     * @type {number}
     * @memberof NetworkStatusResponse
     */
    activeValidator: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkStatusResponse
     */
    totalDelegator: number;
}

export function NetworkStatusResponseFromJSON(json: any): NetworkStatusResponse {
    return NetworkStatusResponseFromJSONTyped(json, false);
}

export function NetworkStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'curHeight': json['curHeight'],
        'curEpoch': json['curEpoch'],
        'totalTxs': json['totalTxs'],
        'totalEscrow': json['totalEscrow'],
        'activeValidator': json['activeValidator'],
        'totalDelegator': json['totalDelegator'],
    };
}

export function NetworkStatusResponseToJSON(value?: NetworkStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'curHeight': value.curHeight,
        'curEpoch': value.curEpoch,
        'totalTxs': value.totalTxs,
        'totalEscrow': value.totalEscrow,
        'activeValidator': value.activeValidator,
        'totalDelegator': value.totalDelegator,
    };
}


