/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValidatorAggStats,
    ValidatorAggStatsFromJSON,
    ValidatorAggStatsFromJSONTyped,
    ValidatorAggStatsToJSON,
    ValidatorList,
    ValidatorListFromJSON,
    ValidatorListFromJSONTyped,
    ValidatorListToJSON,
} from './';

/**
 * 
 * @export
 * @interface ValidatorsResponse
 */
export interface ValidatorsResponse {
    /**
     * 
     * @type {ValidatorList}
     * @memberof ValidatorsResponse
     */
    validator_list: ValidatorList;
    /**
     * Summary statistics across all consensus validators.
     * @type {ValidatorAggStats}
     * @memberof ValidatorsResponse
     */
    stats: ValidatorAggStats;
}

export function ValidatorsResponseFromJSON(json: any): ValidatorsResponse {
    return ValidatorsResponseFromJSONTyped(json, false);
}

export function ValidatorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validator_list': ValidatorListFromJSON(json['validator_list']),
        'stats': ValidatorAggStatsFromJSON(json['stats']),
    };
}

export function ValidatorsResponseToJSON(value?: ValidatorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validator_list': ValidatorListToJSON(value.validator_list),
        'stats': ValidatorAggStatsToJSON(value.stats),
    };
}


