/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProposalOption,
    ProposalOptionFromJSON,
    ProposalOptionFromJSONTyped,
    ProposalOptionToJSON,
    ProposalVote,
    ProposalVoteFromJSON,
    ProposalVoteFromJSONTyped,
    ProposalVoteToJSON,
} from './';

/**
 * 
 * @export
 * @interface GovernanceProposalWithVotesResponse
 */
export interface GovernanceProposalWithVotesResponse {
    /**
     * 
     * @type {Array<ProposalOption>}
     * @memberof GovernanceProposalWithVotesResponse
     */
    options: Array<ProposalOption>;
    /**
     * 
     * @type {Array<ProposalVote>}
     * @memberof GovernanceProposalWithVotesResponse
     */
    votes: Array<ProposalVote>;
}

export function GovernanceProposalWithVotesResponseFromJSON(json: any): GovernanceProposalWithVotesResponse {
    return GovernanceProposalWithVotesResponseFromJSONTyped(json, false);
}

export function GovernanceProposalWithVotesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceProposalWithVotesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': ((json['options'] as Array<any>).map(ProposalOptionFromJSON)),
        'votes': ((json['votes'] as Array<any>).map(ProposalVoteFromJSON)),
    };
}

export function GovernanceProposalWithVotesResponseToJSON(value?: GovernanceProposalWithVotesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': ((value.options as Array<any>).map(ProposalOptionToJSON)),
        'votes': ((value.votes as Array<any>).map(ProposalVoteToJSON)),
    };
}


