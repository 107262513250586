/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Layer {
    Emerald = 'emerald',
    Sapphire = 'sapphire',
    Pontusxtest = 'pontusxtest',
    Pontusxdev = 'pontusxdev',
    Cipher = 'cipher',
    Consensus = 'consensus'
}

export function LayerFromJSON(json: any): Layer {
    return LayerFromJSONTyped(json, false);
}

export function LayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Layer {
    return json as Layer;
}

export function LayerToJSON(value?: Layer | null): any {
    return value as any;
}

