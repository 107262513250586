"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromBigInt = exports.toBigInt = void 0;
const misc = require("./misc");
function toBigInt(q) {
    if (q.length === 0)
        return 0n;
    return BigInt('0x' + misc.toHex(q));
}
exports.toBigInt = toBigInt;
function fromBigInt(bi) {
    if (bi === 0n)
        return new Uint8Array();
    let hex = bi.toString(16);
    if (hex.length % 2) {
        hex = '0' + hex;
    }
    return misc.fromHex(hex);
}
exports.fromBigInt = fromBigInt;
