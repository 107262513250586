/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorRewardsStat
 */
export interface ValidatorRewardsStat {
    /**
     * 
     * @type {string}
     * @memberof ValidatorRewardsStat
     */
    entity_address?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatorRewardsStat
     */
    total_reward?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRewardsStat
     */
    day_reward?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRewardsStat
     */
    week_reward?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRewardsStat
     */
    month_reward?: number;
}

export function ValidatorRewardsStatFromJSON(json: any): ValidatorRewardsStat {
    return ValidatorRewardsStatFromJSONTyped(json, false);
}

export function ValidatorRewardsStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorRewardsStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_address': !exists(json, 'entity_address') ? undefined : json['entity_address'],
        'total_reward': !exists(json, 'total_reward') ? undefined : json['total_reward'],
        'day_reward': !exists(json, 'day_reward') ? undefined : json['day_reward'],
        'week_reward': !exists(json, 'week_reward') ? undefined : json['week_reward'],
        'month_reward': !exists(json, 'month_reward') ? undefined : json['month_reward'],
    };
}

export function ValidatorRewardsStatToJSON(value?: ValidatorRewardsStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_address': value.entity_address,
        'total_reward': value.total_reward,
        'day_reward': value.day_reward,
        'week_reward': value.week_reward,
        'month_reward': value.month_reward,
    };
}


