/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    List,
    ListFromJSON,
    ListFromJSONTyped,
    ListToJSON,
    ProposalVote,
    ProposalVoteFromJSON,
    ProposalVoteFromJSONTyped,
    ProposalVoteToJSON,
    ProposalVotesAllOf,
    ProposalVotesAllOfFromJSON,
    ProposalVotesAllOfFromJSONTyped,
    ProposalVotesAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProposalVotes
 */
export interface ProposalVotes {
    /**
     * The total number of records that match the query, i.e. the number of records
     * the query would return with limit=infinity.
     * @type {number}
     * @memberof ProposalVotes
     */
    total_count: number;
    /**
     * Whether total_count is clipped for performance reasons.
     * @type {boolean}
     * @memberof ProposalVotes
     */
    is_total_count_clipped: boolean;
    /**
     * The unique identifier of the proposal.
     * @type {number}
     * @memberof ProposalVotes
     */
    proposal_id: number;
    /**
     * The list of votes for the proposal.
     * @type {Array<ProposalVote>}
     * @memberof ProposalVotes
     */
    votes: Array<ProposalVote>;
}

export function ProposalVotesFromJSON(json: any): ProposalVotes {
    return ProposalVotesFromJSONTyped(json, false);
}

export function ProposalVotesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalVotes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_count': json['total_count'],
        'is_total_count_clipped': json['is_total_count_clipped'],
        'proposal_id': json['proposal_id'],
        'votes': ((json['votes'] as Array<any>).map(ProposalVoteFromJSON)),
    };
}

export function ProposalVotesToJSON(value?: ProposalVotes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_count': value.total_count,
        'is_total_count_clipped': value.is_total_count_clipped,
        'proposal_id': value.proposal_id,
        'votes': ((value.votes as Array<any>).map(ProposalVoteToJSON)),
    };
}


