"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.castVoteWrapper = exports.submitProposalWrapper = exports.ERR_VOTING_IS_CLOSED_CODE = exports.ERR_NOT_ELIGIBLE_CODE = exports.ERR_NO_SUCH_PROPOSAL_CODE = exports.ERR_NO_SUCH_UPGRADE_CODE = exports.ERR_UPGRADE_ALREADY_PENDING_CODE = exports.ERR_UPGRADE_TOO_SOON_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = exports.VOTE_ABSTAIN = exports.VOTE_NO = exports.VOTE_YES = exports.PROPOSAL_CONTENT_INVALID_TEXT = exports.STATE_FAILED = exports.STATE_REJECTED = exports.STATE_PASSED = exports.STATE_ACTIVE = exports.METHOD_CAST_VOTE = exports.METHOD_SUBMIT_PROPOSAL = void 0;
const consensus = require("./consensus");
/**
 * MethodSubmitProposal submits a new consensus layer governance proposal.
 */
exports.METHOD_SUBMIT_PROPOSAL = 'governance.SubmitProposal';
/**
 * MethodCastVote casts a vote for a consensus layer governance proposal.
 */
exports.METHOD_CAST_VOTE = 'governance.CastVote';
exports.STATE_ACTIVE = 1;
exports.STATE_PASSED = 2;
exports.STATE_REJECTED = 3;
exports.STATE_FAILED = 4;
/**
 * ProposalContentInvalidText is the textual representation of an invalid
 * ProposalContent.
 */
exports.PROPOSAL_CONTENT_INVALID_TEXT = '(invalid)';
exports.VOTE_YES = 1;
exports.VOTE_NO = 2;
exports.VOTE_ABSTAIN = 3;
/**
 * ModuleName is a unique module name for the governance backend.
 */
exports.MODULE_NAME = 'governance';
/**
 * ErrInvalidArgument is the error returned on malformed argument(s).
 */
exports.ERR_INVALID_ARGUMENT_CODE = 1;
/**
 * ErrUpgradeTooSoon is the error returned when an upgrade is not enough in the future.
 */
exports.ERR_UPGRADE_TOO_SOON_CODE = 2;
/**
 * ErrUpgradeAlreadyPending is the error returned when an upgrade is already pending.
 */
exports.ERR_UPGRADE_ALREADY_PENDING_CODE = 3;
/**
 * ErrNoSuchUpgrade is the error returned when an upgrade does not exist.
 */
exports.ERR_NO_SUCH_UPGRADE_CODE = 4;
/**
 * ErrNoSuchProposal is the error retrued when a proposal does not exist.
 */
exports.ERR_NO_SUCH_PROPOSAL_CODE = 5;
/**
 * ErrNotEligible is the error returned when a vote caster is not eligible for a vote.
 */
exports.ERR_NOT_ELIGIBLE_CODE = 6;
/**
 * ErrVotingIsClosed is the error returned when a vote is cast for a non-active proposal.
 */
exports.ERR_VOTING_IS_CLOSED_CODE = 7;
function submitProposalWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_SUBMIT_PROPOSAL);
}
exports.submitProposalWrapper = submitProposalWrapper;
function castVoteWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_CAST_VOTE);
}
exports.castVoteWrapper = castVoteWrapper;
