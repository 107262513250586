/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountAllowance,
    AccountAllowanceFromJSON,
    AccountAllowanceFromJSONTyped,
    AccountAllowanceToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountInfoResponse
 */
export interface AccountInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    available: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    escrow: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    debonding: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoResponse
     */
    total: string;
    /**
     * 
     * @type {number}
     * @memberof AccountInfoResponse
     */
    nonce: number;
    /**
     * 
     * @type {Array<AccountAllowance>}
     * @memberof AccountInfoResponse
     */
    allowances: Array<AccountAllowance>;
}

export function AccountInfoResponseFromJSON(json: any): AccountInfoResponse {
    return AccountInfoResponseFromJSONTyped(json, false);
}

export function AccountInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'available': json['available'],
        'escrow': json['escrow'],
        'debonding': json['debonding'],
        'total': json['total'],
        'nonce': json['nonce'],
        'allowances': ((json['allowances'] as Array<any>).map(AccountAllowanceFromJSON)),
    };
}

export function AccountInfoResponseToJSON(value?: AccountInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'available': value.available,
        'escrow': value.escrow,
        'debonding': value.debonding,
        'total': value.total,
        'nonce': value.nonce,
        'allowances': ((value.allowances as Array<any>).map(AccountAllowanceToJSON)),
    };
}


