/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Proposal,
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './';

/**
 * A list of governance proposals.
 * @export
 * @interface ProposalListAllOf
 */
export interface ProposalListAllOf {
    /**
     * 
     * @type {Array<Proposal>}
     * @memberof ProposalListAllOf
     */
    proposals: Array<Proposal>;
}

export function ProposalListAllOfFromJSON(json: any): ProposalListAllOf {
    return ProposalListAllOfFromJSONTyped(json, false);
}

export function ProposalListAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalListAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposals': ((json['proposals'] as Array<any>).map(ProposalFromJSON)),
    };
}

export function ProposalListAllOfToJSON(value?: ProposalListAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposals': ((value.proposals as Array<any>).map(ProposalToJSON)),
    };
}


