/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvmTokenType,
    EvmTokenTypeFromJSON,
    EvmTokenTypeFromJSONTyped,
    EvmTokenTypeToJSON,
    VerificationLevel,
    VerificationLevelFromJSON,
    VerificationLevelFromJSONTyped,
    VerificationLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvmToken
 */
export interface EvmToken {
    /**
     * The Oasis address of this token's contract.
     * @type {string}
     * @memberof EvmToken
     */
    contract_addr: string;
    /**
     * The Ethereum address of this token's contract.
     * @type {string}
     * @memberof EvmToken
     */
    eth_contract_addr: string;
    /**
     * Name of the token, as provided by token contract's `name()` method.
     * @type {string}
     * @memberof EvmToken
     */
    name?: string;
    /**
     * Symbol of the token, as provided by token contract's `symbol()` method.
     * @type {string}
     * @memberof EvmToken
     */
    symbol?: string;
    /**
     * The number of least significant digits in base units that should be displayed as
     * decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
     * Affects display only. Often equals 18, to match ETH.
     * @type {number}
     * @memberof EvmToken
     */
    decimals?: number;
    /**
     * The heuristically determined interface that the token contract implements.
     * A less specialized variant of the token might be detected; for example, an
     * ERC-1363 token might be labeled as ERC-20 here. If the type cannot be
     * detected or is not supported, this field will be null/absent.
     * @type {EvmTokenType}
     * @memberof EvmToken
     */
    type: EvmTokenType;
    /**
     * The total number of base units available.
     * @type {string}
     * @memberof EvmToken
     */
    total_supply?: string;
    /**
     * The total number of transfers of this token.
     * @type {number}
     * @memberof EvmToken
     */
    num_transfers?: number;
    /**
     * The number of addresses that have a nonzero balance of this token.
     * @type {number}
     * @memberof EvmToken
     */
    num_holders: number;
    /**
     * Whether the contract has been successfully verified by Sourcify.
     * Additional information on verified contracts is available via
     * the `/{runtime}/accounts/{address}` endpoint.
     * DEPRECATED: This field will be removed in the future in favor of verification_level
     * @type {boolean}
     * @memberof EvmToken
     */
    is_verified: boolean;
    /**
     * 
     * @type {VerificationLevel}
     * @memberof EvmToken
     */
    verification_level?: VerificationLevel;
}

export function EvmTokenFromJSON(json: any): EvmToken {
    return EvmTokenFromJSONTyped(json, false);
}

export function EvmTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvmToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contract_addr': json['contract_addr'],
        'eth_contract_addr': json['eth_contract_addr'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'decimals': !exists(json, 'decimals') ? undefined : json['decimals'],
        'type': EvmTokenTypeFromJSON(json['type']),
        'total_supply': !exists(json, 'total_supply') ? undefined : json['total_supply'],
        'num_transfers': !exists(json, 'num_transfers') ? undefined : json['num_transfers'],
        'num_holders': json['num_holders'],
        'is_verified': json['is_verified'],
        'verification_level': !exists(json, 'verification_level') ? undefined : VerificationLevelFromJSON(json['verification_level']),
    };
}

export function EvmTokenToJSON(value?: EvmToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contract_addr': value.contract_addr,
        'eth_contract_addr': value.eth_contract_addr,
        'name': value.name,
        'symbol': value.symbol,
        'decimals': value.decimals,
        'type': EvmTokenTypeToJSON(value.type),
        'total_supply': value.total_supply,
        'num_transfers': value.num_transfers,
        'num_holders': value.num_holders,
        'is_verified': value.is_verified,
        'verification_level': VerificationLevelToJSON(value.verification_level),
    };
}


