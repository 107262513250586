/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RuntimeListResponse,
    RuntimeListResponseFromJSON,
    RuntimeListResponseToJSON,
    RuntimeRoundInfoResponse,
    RuntimeRoundInfoResponseFromJSON,
    RuntimeRoundInfoResponseToJSON,
    RuntimeRoundListResponse,
    RuntimeRoundListResponseFromJSON,
    RuntimeRoundListResponseToJSON,
    RuntimeStatsResponse,
    RuntimeStatsResponseFromJSON,
    RuntimeStatsResponseToJSON,
    RuntimeTransactionInfoResponse,
    RuntimeTransactionInfoResponseFromJSON,
    RuntimeTransactionInfoResponseToJSON,
    RuntimeTransactionListResponse,
    RuntimeTransactionListResponseFromJSON,
    RuntimeTransactionListResponseToJSON,
} from '../models';

export interface RuntimeRoundInfoHandlerRequest {
    id: string;
    round: number;
}

export interface RuntimeRoundListHandlerRequest {
    id: string;
    page: number;
    size: number;
}

export interface RuntimeStatsHandlerRequest {
    id: string;
}

export interface RuntimeTransactionInfoHandlerRequest {
    id: string;
    round: number;
    hash: string;
}

export interface RuntimeTransactionListHandlerRequest {
    page: number;
    size: number;
    id?: string;
    address?: string;
    round?: number;
}

/**
 * 
 */
export class RuntimeApi extends runtime.BaseAPI {

    /**
     */
    async runtimeListHandlerRaw(): Promise<runtime.ApiResponse<RuntimeListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeListResponseFromJSON(jsonValue));
    }

    /**
     */
    async runtimeListHandler(): Promise<RuntimeListResponse> {
        const response = await this.runtimeListHandlerRaw();
        return await response.value();
    }

    /**
     */
    async runtimeRoundInfoHandlerRaw(requestParameters: RuntimeRoundInfoHandlerRequest): Promise<runtime.ApiResponse<RuntimeRoundInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling runtimeRoundInfoHandler.');
        }

        if (requestParameters.round === null || requestParameters.round === undefined) {
            throw new runtime.RequiredError('round','Required parameter requestParameters.round was null or undefined when calling runtimeRoundInfoHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.round !== undefined) {
            queryParameters['round'] = requestParameters.round;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/round/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeRoundInfoResponseFromJSON(jsonValue));
    }

    /**
     */
    async runtimeRoundInfoHandler(requestParameters: RuntimeRoundInfoHandlerRequest): Promise<RuntimeRoundInfoResponse> {
        const response = await this.runtimeRoundInfoHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async runtimeRoundListHandlerRaw(requestParameters: RuntimeRoundListHandlerRequest): Promise<runtime.ApiResponse<RuntimeRoundListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling runtimeRoundListHandler.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling runtimeRoundListHandler.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling runtimeRoundListHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/round/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeRoundListResponseFromJSON(jsonValue));
    }

    /**
     */
    async runtimeRoundListHandler(requestParameters: RuntimeRoundListHandlerRequest): Promise<RuntimeRoundListResponse> {
        const response = await this.runtimeRoundListHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async runtimeStatsHandlerRaw(requestParameters: RuntimeStatsHandlerRequest): Promise<runtime.ApiResponse<RuntimeStatsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling runtimeStatsHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async runtimeStatsHandler(requestParameters: RuntimeStatsHandlerRequest): Promise<RuntimeStatsResponse> {
        const response = await this.runtimeStatsHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async runtimeTransactionInfoHandlerRaw(requestParameters: RuntimeTransactionInfoHandlerRequest): Promise<runtime.ApiResponse<RuntimeTransactionInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling runtimeTransactionInfoHandler.');
        }

        if (requestParameters.round === null || requestParameters.round === undefined) {
            throw new runtime.RequiredError('round','Required parameter requestParameters.round was null or undefined when calling runtimeTransactionInfoHandler.');
        }

        if (requestParameters.hash === null || requestParameters.hash === undefined) {
            throw new runtime.RequiredError('hash','Required parameter requestParameters.hash was null or undefined when calling runtimeTransactionInfoHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.round !== undefined) {
            queryParameters['round'] = requestParameters.round;
        }

        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/transaction/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeTransactionInfoResponseFromJSON(jsonValue.data));
    }

    /**
     */
    async runtimeTransactionInfoHandler(requestParameters: RuntimeTransactionInfoHandlerRequest): Promise<RuntimeTransactionInfoResponse> {
        const response = await this.runtimeTransactionInfoHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async runtimeTransactionListHandlerRaw(requestParameters: RuntimeTransactionListHandlerRequest): Promise<runtime.ApiResponse<RuntimeTransactionListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling runtimeTransactionListHandler.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling runtimeTransactionListHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.round !== undefined) {
            queryParameters['round'] = requestParameters.round;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/runtime/transaction/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuntimeTransactionListResponseFromJSON(jsonValue));
    }

    /**
     */
    async runtimeTransactionListHandler(requestParameters: RuntimeTransactionListHandlerRequest): Promise<RuntimeTransactionListResponse> {
        const response = await this.runtimeTransactionListHandlerRaw(requestParameters);
        return await response.value();
    }

}
