"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleEventHandler = exports.Wrapper = exports.METHODHANDLERKIND_MESSAGE_RESULT = exports.METHODHANDLERKIND_QUERY = exports.METHODHANDLERKIND_CALL = exports.EVENT_GAS_USED = exports.METHOD_RUNTIME_INFO = exports.METHOD_MIN_GAS_PRICE = exports.METHOD_CALL_DATA_PUBLIC_KEY = exports.METHOD_CHECK_INVARIANTS = exports.METHOD_ESTIMATE_GAS = exports.ERR_FORBIDDEN_BY_NODE_POLICY = exports.ERR_FORBIDDEN_IN_SECURE_BUILD = exports.ERR_GAS_PRICE_TOO_LOW_CODE = exports.ERR_NOT_AUTHENTICATED_CODE = exports.ERR_INVALID_CALL_FORMAT_CODE = exports.ERR_INVARIANT_VIOLATION_CODE = exports.ERR_MULTISIG_TOO_MANY_SIGNERS_CODE = exports.ERR_TOO_MANY_AUTH_CODE = exports.ERR_BATCH_OUT_OF_GAS_CODE = exports.ERR_BATCH_GAS_OVERFLOW_CODE = exports.ERR_OUT_OF_GAS_CODE = exports.ERR_GAS_OVERFLOW_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.ERR_MESSAGE_HANDLER_MISSING_CODE = exports.ERR_MESSAGE_NOT_HANDLED_CODE = exports.ERR_OUT_OF_MESSAGE_SLOTS_CODE = exports.ERR_INSUFFICIENT_FEE_BALANCE_CODE = exports.ERR_INVALID_NONCE_CODE = exports.ERR_INVALID_METHOD_CODE = exports.ERR_INVALID_TRANSACTION_CODE = exports.ERR_MALFORMED_TRANSACTION_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'core';
exports.ERR_MALFORMED_TRANSACTION_CODE = 1;
exports.ERR_INVALID_TRANSACTION_CODE = 2;
exports.ERR_INVALID_METHOD_CODE = 3;
exports.ERR_INVALID_NONCE_CODE = 4;
exports.ERR_INSUFFICIENT_FEE_BALANCE_CODE = 5;
exports.ERR_OUT_OF_MESSAGE_SLOTS_CODE = 6;
exports.ERR_MESSAGE_NOT_HANDLED_CODE = 8;
exports.ERR_MESSAGE_HANDLER_MISSING_CODE = 9;
exports.ERR_INVALID_ARGUMENT_CODE = 10;
exports.ERR_GAS_OVERFLOW_CODE = 11;
exports.ERR_OUT_OF_GAS_CODE = 12;
exports.ERR_BATCH_GAS_OVERFLOW_CODE = 13;
exports.ERR_BATCH_OUT_OF_GAS_CODE = 14;
exports.ERR_TOO_MANY_AUTH_CODE = 15;
exports.ERR_MULTISIG_TOO_MANY_SIGNERS_CODE = 16;
exports.ERR_INVARIANT_VIOLATION_CODE = 17;
exports.ERR_INVALID_CALL_FORMAT_CODE = 18;
exports.ERR_NOT_AUTHENTICATED_CODE = 19;
exports.ERR_GAS_PRICE_TOO_LOW_CODE = 20;
exports.ERR_FORBIDDEN_IN_SECURE_BUILD = 21;
exports.ERR_FORBIDDEN_BY_NODE_POLICY = 22;
// Queries.
exports.METHOD_ESTIMATE_GAS = 'core.EstimateGas';
exports.METHOD_CHECK_INVARIANTS = 'core.CheckInvariants';
exports.METHOD_CALL_DATA_PUBLIC_KEY = 'core.CallDataPublicKey';
exports.METHOD_MIN_GAS_PRICE = 'core.MinGasPrice';
exports.METHOD_RUNTIME_INFO = 'core.RuntimeInfo';
// Events.
exports.EVENT_GAS_USED = 1;
// Introspection.
// Keep these in sync with `CoreMethodHandlerInfo.kind`
exports.METHODHANDLERKIND_CALL = 'call';
exports.METHODHANDLERKIND_QUERY = 'query';
exports.METHODHANDLERKIND_MESSAGE_RESULT = 'message_result';
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    queryEstimateGas() {
        return this.query(exports.METHOD_ESTIMATE_GAS);
    }
    queryCheckInvariants() {
        return this.query(exports.METHOD_CHECK_INVARIANTS);
    }
    queryCallDataPublicKey() {
        return this.query(exports.METHOD_CALL_DATA_PUBLIC_KEY);
    }
    queryMinGasPrice() {
        return this.query(exports.METHOD_MIN_GAS_PRICE);
    }
    queryRuntimeInfo() {
        return this.query(exports.METHOD_RUNTIME_INFO);
    }
}
exports.Wrapper = Wrapper;
function moduleEventHandler(codes) {
    return [exports.MODULE_NAME, codes];
}
exports.moduleEventHandler = moduleEventHandler;
