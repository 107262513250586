/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountDebondingInfo
 */
export interface AccountDebondingInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountDebondingInfo
     */
    validatorAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDebondingInfo
     */
    validatorName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDebondingInfo
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDebondingInfo
     */
    shares: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDebondingInfo
     */
    debondEnd: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDebondingInfo
     */
    epochLeft: number;
}

export function AccountDebondingInfoFromJSON(json: any): AccountDebondingInfo {
    return AccountDebondingInfoFromJSONTyped(json, false);
}

export function AccountDebondingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDebondingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validatorAddress': json['validatorAddress'],
        'validatorName': json['validatorName'],
        'icon': json['icon'],
        'shares': json['shares'],
        'debondEnd': json['debondEnd'],
        'epochLeft': json['epochLeft'],
    };
}

export function AccountDebondingInfoToJSON(value?: AccountDebondingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validatorAddress': value.validatorAddress,
        'validatorName': value.validatorName,
        'icon': value.icon,
        'shares': value.shares,
        'debondEnd': value.debondEnd,
        'epochLeft': value.epochLeft,
    };
}


