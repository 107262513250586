"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = exports.METHOD_PARAMETERS = exports.ERR_AMOUNT_NOT_REPRESENTABLE_CODE = exports.ERR_CONSENSUS_INCOMPATIBLE_SIGNER_CODE = exports.ERR_INTERNAL_STATE_ERROR_CODE = exports.ERR_INVALID_DENOMINATION_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'consensus';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
exports.ERR_INVALID_DENOMINATION_CODE = 2;
exports.ERR_INTERNAL_STATE_ERROR_CODE = 3;
exports.ERR_CONSENSUS_INCOMPATIBLE_SIGNER_CODE = 4;
exports.ERR_AMOUNT_NOT_REPRESENTABLE_CODE = 5;
// Queries.
exports.METHOD_PARAMETERS = 'consensus.Parameters';
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    queryAccount() {
        return this.query(exports.METHOD_PARAMETERS);
    }
}
exports.Wrapper = Wrapper;
