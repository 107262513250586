"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = exports.PUBLIC_KEY_KIND_TRANSACTION = exports.STORE_KIND_CONFIDENTIAL = exports.STORE_KIND_PUBLIC = exports.METHOD_CUSTOM = exports.METHOD_PUBLIC_KEY = exports.METHOD_INSTANCE_RAW_STORAGE = exports.METHOD_INSTANCE_STORAGE = exports.METHOD_INSTANCE = exports.METHOD_CODE_STORAGE = exports.METHOD_CODE = exports.METHOD_CHANGE_UPGRADE_POLICY = exports.METHOD_UPGRADE = exports.METHOD_CALL = exports.METHOD_INSTANTIATE = exports.METHOD_UPLOAD = exports.ERR_CODE_ALREADY_UPGRADED_CODE = exports.ERR_TOO_MANY_SUBCALLS_CODE = exports.ERR_RESULT_TOO_LARGE_CODE = exports.ERR_CALL_DEPTH_EXCEEDED_CODE = exports.ERR_INSUFFICIENT_CALLER_BALANCE_CODE = exports.ERR_UNSUPPORTED_CODE = exports.ERR_FORBIDDEN_CODE = exports.ERR_EXECUTION_FAILED_CODE = exports.ERR_MODULE_LOADING_FAILED_CODE = exports.ERR_INSTANCE_NOT_FOUND_CODE = exports.ERR_CODE_NOT_FOUND_CODE = exports.ERR_CODE_DECLARES_TOO_MANY_MEMORIES_CODE = exports.ERR_CODE_DECLARES_START_FUNCTION_CODE = exports.ERR_CODE_DECLARES_RESERVED_EXPORT_CODE = exports.ERR_CODE_MISSING_REQUIRED_EXPORT_CODE = exports.ERR_UNSUPPORTED_ABI_CODE = exports.ERR_CODE_MALFORMED_CODE = exports.ERR_CODE_TOO_LARGE_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'contracts';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
exports.ERR_CODE_TOO_LARGE_CODE = 2;
exports.ERR_CODE_MALFORMED_CODE = 3;
exports.ERR_UNSUPPORTED_ABI_CODE = 4;
exports.ERR_CODE_MISSING_REQUIRED_EXPORT_CODE = 5;
exports.ERR_CODE_DECLARES_RESERVED_EXPORT_CODE = 6;
exports.ERR_CODE_DECLARES_START_FUNCTION_CODE = 7;
exports.ERR_CODE_DECLARES_TOO_MANY_MEMORIES_CODE = 8;
exports.ERR_CODE_NOT_FOUND_CODE = 9;
exports.ERR_INSTANCE_NOT_FOUND_CODE = 10;
exports.ERR_MODULE_LOADING_FAILED_CODE = 11;
exports.ERR_EXECUTION_FAILED_CODE = 12;
exports.ERR_FORBIDDEN_CODE = 13;
exports.ERR_UNSUPPORTED_CODE = 14;
exports.ERR_INSUFFICIENT_CALLER_BALANCE_CODE = 15;
exports.ERR_CALL_DEPTH_EXCEEDED_CODE = 16;
exports.ERR_RESULT_TOO_LARGE_CODE = 17;
exports.ERR_TOO_MANY_SUBCALLS_CODE = 18;
exports.ERR_CODE_ALREADY_UPGRADED_CODE = 19;
// Callable methods.
exports.METHOD_UPLOAD = 'contracts.Upload';
exports.METHOD_INSTANTIATE = 'contracts.Instantiate';
exports.METHOD_CALL = 'contracts.Call';
exports.METHOD_UPGRADE = 'contracts.Upgrade';
exports.METHOD_CHANGE_UPGRADE_POLICY = 'contracts.ChangeUpgradePolicy';
// Queries.
exports.METHOD_CODE = 'contracts.Code';
exports.METHOD_CODE_STORAGE = 'contracts.CodeStorage';
exports.METHOD_INSTANCE = 'contracts.Instance';
exports.METHOD_INSTANCE_STORAGE = 'contracts.InstanceStorage';
exports.METHOD_INSTANCE_RAW_STORAGE = 'contracts.InstanceRawStorage';
exports.METHOD_PUBLIC_KEY = 'contracts.PublicKey';
exports.METHOD_CUSTOM = 'contracts.Custom';
// Store kind.
exports.STORE_KIND_PUBLIC = 0;
exports.STORE_KIND_CONFIDENTIAL = 1;
// Public key kind.
exports.PUBLIC_KEY_KIND_TRANSACTION = 1;
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    callUpload() {
        return this.call(exports.METHOD_UPLOAD);
    }
    callInstantiate() {
        return this.call(exports.METHOD_INSTANTIATE);
    }
    callCall() {
        return this.call(exports.METHOD_CALL);
    }
    callUpgrade() {
        return this.call(exports.METHOD_UPGRADE);
    }
    callChangeUpgradePolicy() {
        return this.call(exports.METHOD_CHANGE_UPGRADE_POLICY);
    }
    queryCode() {
        return this.query(exports.METHOD_CODE);
    }
    queryCodeStorage() {
        return this.query(exports.METHOD_CODE_STORAGE);
    }
    queryInstance() {
        return this.query(exports.METHOD_INSTANCE);
    }
    queryInstanceStorage() {
        return this.query(exports.METHOD_INSTANCE_STORAGE);
    }
    queryInstanceRawStorage() {
        return this.query(exports.METHOD_INSTANCE_RAW_STORAGE);
    }
    queryPublicKey() {
        return this.query(exports.METHOD_PUBLIC_KEY);
    }
    queryCustom() {
        return this.query(exports.METHOD_CUSTOM);
    }
}
exports.Wrapper = Wrapper;
