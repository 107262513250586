/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsensusEvent,
    ConsensusEventFromJSON,
    ConsensusEventFromJSONTyped,
    ConsensusEventToJSON,
} from './';

/**
 * A list of consensus events.
 * @export
 * @interface ConsensusEventListAllOf
 */
export interface ConsensusEventListAllOf {
    /**
     * 
     * @type {Array<ConsensusEvent>}
     * @memberof ConsensusEventListAllOf
     */
    events: Array<ConsensusEvent>;
}

export function ConsensusEventListAllOfFromJSON(json: any): ConsensusEventListAllOf {
    return ConsensusEventListAllOfFromJSONTyped(json, false);
}

export function ConsensusEventListAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsensusEventListAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(ConsensusEventFromJSON)),
    };
}

export function ConsensusEventListAllOfToJSON(value?: ConsensusEventListAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': ((value.events as Array<any>).map(ConsensusEventToJSON)),
    };
}


