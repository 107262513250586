/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuntimeTransactionEvmTx
 */
export interface RuntimeTransactionEvmTx {
    /**
     * 
     * @type {string}
     * @memberof RuntimeTransactionEvmTx
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeTransactionEvmTx
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeTransactionEvmTx
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof RuntimeTransactionEvmTx
     */
    nonce: number;
    /**
     * 
     * @type {number}
     * @memberof RuntimeTransactionEvmTx
     */
    gasPrice: number;
    /**
     * 
     * @type {number}
     * @memberof RuntimeTransactionEvmTx
     */
    gasLimit: number;
    /**
     * 
     * @type {string}
     * @memberof RuntimeTransactionEvmTx
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeTransactionEvmTx
     */
    value: string;
}

export function RuntimeTransactionEvmTxFromJSON(json: any): RuntimeTransactionEvmTx {
    return RuntimeTransactionEvmTxFromJSONTyped(json, false);
}

export function RuntimeTransactionEvmTxFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeTransactionEvmTx {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hash': json['hash'],
        'from': json['from'],
        'to': json['to'],
        'nonce': json['nonce'],
        'gasPrice': json['gasPrice'],
        'gasLimit': json['gasLimit'],
        'data': json['data'],
        'value': json['value'],
    };
}

export function RuntimeTransactionEvmTxToJSON(value?: RuntimeTransactionEvmTx | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hash': value.hash,
        'from': value.from,
        'to': value.to,
        'nonce': value.nonce,
        'gasPrice': value.gasPrice,
        'gasLimit': value.gasLimit,
        'data': value.data,
        'value': value.value,
    };
}


