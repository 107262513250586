/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Runtime {
    Emerald = 'emerald',
    Sapphire = 'sapphire',
    Pontusxtest = 'pontusxtest',
    Pontusxdev = 'pontusxdev',
    Cipher = 'cipher'
}

export function RuntimeFromJSON(json: any): Runtime {
    return RuntimeFromJSONTyped(json, false);
}

export function RuntimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Runtime {
    return json as Runtime;
}

export function RuntimeToJSON(value?: Runtime | null): any {
    return value as any;
}

