/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    List,
    ListFromJSON,
    ListFromJSONTyped,
    ListToJSON,
    RuntimeEvent,
    RuntimeEventFromJSON,
    RuntimeEventFromJSONTyped,
    RuntimeEventToJSON,
    RuntimeEventListAllOf,
    RuntimeEventListAllOfFromJSON,
    RuntimeEventListAllOfFromJSONTyped,
    RuntimeEventListAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuntimeEventList
 */
export interface RuntimeEventList {
    /**
     * The total number of records that match the query, i.e. the number of records
     * the query would return with limit=infinity.
     * @type {number}
     * @memberof RuntimeEventList
     */
    total_count: number;
    /**
     * Whether total_count is clipped for performance reasons.
     * @type {boolean}
     * @memberof RuntimeEventList
     */
    is_total_count_clipped: boolean;
    /**
     * 
     * @type {Array<RuntimeEvent>}
     * @memberof RuntimeEventList
     */
    events: Array<RuntimeEvent>;
}

export function RuntimeEventListFromJSON(json: any): RuntimeEventList {
    return RuntimeEventListFromJSONTyped(json, false);
}

export function RuntimeEventListFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeEventList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_count': json['total_count'],
        'is_total_count_clipped': json['is_total_count_clipped'],
        'events': ((json['events'] as Array<any>).map(RuntimeEventFromJSON)),
    };
}

export function RuntimeEventListToJSON(value?: RuntimeEventList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_count': value.total_count,
        'is_total_count_clipped': value.is_total_count_clipped,
        'events': ((value.events as Array<any>).map(RuntimeEventToJSON)),
    };
}


