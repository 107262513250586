/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuntimeStatsItem,
    RuntimeStatsItemFromJSON,
    RuntimeStatsItemFromJSONTyped,
    RuntimeStatsItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuntimeStatsInfo
 */
export interface RuntimeStatsInfo {
    /**
     * 
     * @type {string}
     * @memberof RuntimeStatsInfo
     */
    entityId: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeStatsInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RuntimeStatsInfo
     */
    address: string;
    /**
     * 
     * @type {boolean}
     * @memberof RuntimeStatsInfo
     */
    validator: boolean;
    /**
     * 
     * @type {string}
     * @memberof RuntimeStatsInfo
     */
    icon: string;
    /**
     * 
     * @type {boolean}
     * @memberof RuntimeStatsInfo
     */
    status: boolean;
    /**
     * 
     * @type {RuntimeStatsItem}
     * @memberof RuntimeStatsInfo
     */
    stats: RuntimeStatsItem;
}

export function RuntimeStatsInfoFromJSON(json: any): RuntimeStatsInfo {
    return RuntimeStatsInfoFromJSONTyped(json, false);
}

export function RuntimeStatsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeStatsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': json['entityId'],
        'name': json['name'],
        'address': json['address'],
        'validator': json['validator'],
        'icon': json['icon'],
        'status': json['status'],
        'stats': RuntimeStatsItemFromJSON(json['stats']),
    };
}

export function RuntimeStatsInfoToJSON(value?: RuntimeStatsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'name': value.name,
        'address': value.address,
        'validator': value.validator,
        'icon': value.icon,
        'status': value.status,
        'stats': RuntimeStatsItemToJSON(value.stats),
    };
}


