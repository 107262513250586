/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorCommissionBound
 */
export interface ValidatorCommissionBound {
    /**
     * 
     * @type {number}
     * @memberof ValidatorCommissionBound
     */
    lower: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorCommissionBound
     */
    upper: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorCommissionBound
     */
    epoch_start: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorCommissionBound
     */
    epoch_end: number;
}

export function ValidatorCommissionBoundFromJSON(json: any): ValidatorCommissionBound {
    return ValidatorCommissionBoundFromJSONTyped(json, false);
}

export function ValidatorCommissionBoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorCommissionBound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lower': json['lower'],
        'upper': json['upper'],
        'epoch_start': json['epoch_start'],
        'epoch_end': json['epoch_end'],
    };
}

export function ValidatorCommissionBoundToJSON(value?: ValidatorCommissionBound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lower': value.lower,
        'upper': value.upper,
        'epoch_start': value.epoch_start,
        'epoch_end': value.epoch_end,
    };
}


