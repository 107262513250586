/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsensusTxMethod,
    ConsensusTxMethodFromJSON,
    ConsensusTxMethodFromJSONTyped,
    ConsensusTxMethodToJSON,
    TxError,
    TxErrorFromJSON,
    TxErrorFromJSONTyped,
    TxErrorToJSON,
} from './';

/**
 * A consensus transaction.
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * The block height at which this transaction was executed.
     * @type {number}
     * @memberof Transaction
     */
    block: number;
    /**
     * 0-based index of this transaction in its block
     * @type {number}
     * @memberof Transaction
     */
    index: number;
    /**
     * The second-granular consensus time of this tx's block, i.e. roughly when the
     * [block was proposed](https://github.com/tendermint/tendermint/blob/v0.34.x/spec/core/data_structures.md#header).
     * @type {Date}
     * @memberof Transaction
     */
    timestamp: Date;
    /**
     * The cryptographic hash of this transaction's encoding.
     * @type {string}
     * @memberof Transaction
     */
    hash: string;
    /**
     * The address of who sent this transaction.
     * @type {string}
     * @memberof Transaction
     */
    sender: string;
    /**
     * The nonce used with this transaction, to prevent replay.
     * @type {number}
     * @memberof Transaction
     */
    nonce: number;
    /**
     * The fee that this transaction's sender committed
     * to pay to execute it.
     * @type {string}
     * @memberof Transaction
     */
    fee: string;
    /**
     * The maximum gas that a transaction can use.
     * @type {string}
     * @memberof Transaction
     */
    gas_limit: string;
    /**
     * The method that was called.
     * @type {ConsensusTxMethod}
     * @memberof Transaction
     */
    method: ConsensusTxMethod;
    /**
     * The method call body. This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go) of oasis-core. This object will conform to one of the types passed to variable instantiations using `NewMethodName` two levels down the hierarchy, e.g. `MethodTransfer` from `oasis-core/go/staking/api` seen [here](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go@v0.2300.10/staking/api#pkg-variables).
     * @type {object}
     * @memberof Transaction
     */
    body: object;
    /**
     * Whether this transaction successfully executed.
     * @type {boolean}
     * @memberof Transaction
     */
    success: boolean;
    /**
     * Error details of a failed transaction.
     * @type {TxError}
     * @memberof Transaction
     */
    error?: TxError;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': json['block'],
        'index': json['index'],
        'timestamp': (new Date(json['timestamp'])),
        'hash': json['hash'],
        'sender': json['sender'],
        'nonce': json['nonce'],
        'fee': json['fee'],
        'gas_limit': json['gas_limit'],
        'method': ConsensusTxMethodFromJSON(json['method']),
        'body': json['body'],
        'success': json['success'],
        'error': !exists(json, 'error') ? undefined : TxErrorFromJSON(json['error']),
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'index': value.index,
        'timestamp': (value.timestamp.toISOString()),
        'hash': value.hash,
        'sender': value.sender,
        'nonce': value.nonce,
        'fee': value.fee,
        'gas_limit': value.gas_limit,
        'method': ConsensusTxMethodToJSON(value.method),
        'body': value.body,
        'success': value.success,
        'error': TxErrorToJSON(value.error),
    };
}


