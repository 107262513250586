"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = exports.QueryWrapper = exports.TransactionWrapper = void 0;
const oasis = require("@oasisprotocol/client");
const token = require("./token");
const transaction = require("./transaction");
class TransactionWrapper {
    constructor(runtimeID, method) {
        this.runtimeID = runtimeID;
        this.transaction = {
            v: transaction.LATEST_TRANSACTION_VERSION,
            call: {
                method,
                body: undefined,
            },
            ai: {
                si: [],
                fee: {
                    amount: [oasis.quantity.fromBigInt(0n), token.NATIVE_DENOMINATION],
                    gas: 0n,
                    consensus_messages: 0,
                },
            },
        };
        this.unverifiedTransaction = null;
    }
    setBody(body) {
        this.transaction.call.body = body;
        return this;
    }
    setSignerInfo(signerInfo) {
        this.transaction.ai.si = signerInfo;
        return this;
    }
    setFeeAmount(amount) {
        this.transaction.ai.fee.amount = amount;
        return this;
    }
    setFeeGas(gas) {
        this.transaction.ai.fee.gas = gas;
        return this;
    }
    setFeeConsensusMessages(maxMessages) {
        this.transaction.ai.fee.consensus_messages = maxMessages;
        return this;
    }
    /**
     * @param proofProviders An array of providers matching the layout of the
     * transaction's signer info.
     */
    async sign(proofProviders, consensusChainContext) {
        this.unverifiedTransaction = await transaction.signUnverifiedTransaction(proofProviders, this.runtimeID, consensusChainContext, this.transaction);
    }
    async submit(nic) {
        const response = await nic.runtimeClientSubmitTx({
            runtime_id: this.runtimeID,
            data: oasis.misc.toCBOR(this.unverifiedTransaction),
        });
        const result = oasis.misc.fromCBOR(response);
        if (result.fail)
            throw result.fail;
        return result.ok;
    }
    async submitNoWait(nic) {
        await nic.runtimeClientSubmitTxNoWait({
            runtime_id: this.runtimeID,
            data: oasis.misc.toCBOR(this.unverifiedTransaction),
        });
    }
}
exports.TransactionWrapper = TransactionWrapper;
class QueryWrapper {
    constructor(runtimeID, method) {
        this.request = {
            runtime_id: runtimeID,
            round: oasis.runtime.CLIENT_ROUND_LATEST,
            method: method,
            args: oasis.misc.toCBOR(null),
        };
    }
    setArgs(args) {
        this.request.args = oasis.misc.toCBOR(args);
        return this;
    }
    setRound(round) {
        this.request.round = round;
        return this;
    }
    async query(nic) {
        const response = await nic.runtimeClientQuery(this.request);
        return oasis.misc.fromCBOR(response.data);
    }
}
exports.QueryWrapper = QueryWrapper;
class Base {
    constructor(runtimeID) {
        this.runtimeID = runtimeID;
    }
    call(method) {
        return new TransactionWrapper(this.runtimeID, method);
    }
    query(method) {
        return new QueryWrapper(this.runtimeID, method);
    }
}
exports.Base = Base;
