/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceChart
 */
export interface BalanceChart {
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    total_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    escrow_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    debonding_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    delegations_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    debonding_delegations_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceChart
     */
    self_stake_balance?: number;
}

export function BalanceChartFromJSON(json: any): BalanceChart {
    return BalanceChartFromJSONTyped(json, false);
}

export function BalanceChartFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceChart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'total_balance': !exists(json, 'total_balance') ? undefined : json['total_balance'],
        'escrow_balance': !exists(json, 'escrow_balance') ? undefined : json['escrow_balance'],
        'debonding_balance': !exists(json, 'debonding_balance') ? undefined : json['debonding_balance'],
        'delegations_balance': !exists(json, 'delegations_balance') ? undefined : json['delegations_balance'],
        'debonding_delegations_balance': !exists(json, 'debonding_delegations_balance') ? undefined : json['debonding_delegations_balance'],
        'self_stake_balance': !exists(json, 'self_stake_balance') ? undefined : json['self_stake_balance'],
    };
}

export function BalanceChartToJSON(value?: BalanceChart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'total_balance': value.total_balance,
        'escrow_balance': value.escrow_balance,
        'debonding_balance': value.debonding_balance,
        'delegations_balance': value.delegations_balance,
        'debonding_delegations_balance': value.debonding_delegations_balance,
        'self_stake_balance': value.self_stake_balance,
    };
}


