"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STORAGE_ERR_CANT_PAUSE_CHECKPOINTER = exports.STORAGE_ERR_RUNTIME_NOT_FOUND_CODE = exports.STORAGE_MODULE_NAME = void 0;
/**
 * ModuleName is the storage worker module name.
 */
exports.STORAGE_MODULE_NAME = 'worker/storage';
/**
 * ErrRuntimeNotFound is the error returned when the called references an unknown runtime.
 */
exports.STORAGE_ERR_RUNTIME_NOT_FOUND_CODE = 1;
/**
 * ErrCantPauseCheckpointer is the error returned when trying to pause the checkpointer without
 * setting the debug flag.
 */
exports.STORAGE_ERR_CANT_PAUSE_CHECKPOINTER = 2;
