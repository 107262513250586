"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setEpochWrapper = exports.vrfProveWrapper = exports.ERR_BEACON_NOT_AVAILABLE_CODE = exports.MODULE_NAME = exports.BACKEND_VRF = exports.BACKEND_INSECURE = exports.METHOD_SET_EPOCH = exports.METHOD_VRF_PROVE = void 0;
const consensus = require("./consensus");
/**
 * MethodVRFProve is the method name for a VRF proof.
 */
exports.METHOD_VRF_PROVE = 'beacon.VRFProve';
/**
 * MethodSetEpoch is the method name for setting epochs.
 */
exports.METHOD_SET_EPOCH = '000_beacon.SetEpoch';
/**
 * BackendInsecure is the name of the insecure backend.
 */
exports.BACKEND_INSECURE = 'insecure';
/**
 * BackendVRF is the name of the VRF backend.
 */
exports.BACKEND_VRF = 'vrf';
/**
 * ModuleName is a unique module name for the beacon module.
 */
exports.MODULE_NAME = 'beacon';
/**
 * ErrBeaconNotAvailable is the error returned when a beacon is not
 * available for the requested height for any reason.
 */
exports.ERR_BEACON_NOT_AVAILABLE_CODE = 1;
function vrfProveWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_VRF_PROVE);
}
exports.vrfProveWrapper = vrfProveWrapper;
function setEpochWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_SET_EPOCH);
}
exports.setEpochWrapper = setEpochWrapper;
