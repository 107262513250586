/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The target propotocol versions for this upgrade proposal.
 * @export
 * @interface ProposalTarget
 */
export interface ProposalTarget {
    /**
     * 
     * @type {string}
     * @memberof ProposalTarget
     */
    consensus_protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalTarget
     */
    runtime_host_protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalTarget
     */
    runtime_committee_protocol?: string;
}

export function ProposalTargetFromJSON(json: any): ProposalTarget {
    return ProposalTargetFromJSONTyped(json, false);
}

export function ProposalTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consensus_protocol': !exists(json, 'consensus_protocol') ? undefined : json['consensus_protocol'],
        'runtime_host_protocol': !exists(json, 'runtime_host_protocol') ? undefined : json['runtime_host_protocol'],
        'runtime_committee_protocol': !exists(json, 'runtime_committee_protocol') ? undefined : json['runtime_committee_protocol'],
    };
}

export function ProposalTargetToJSON(value?: ProposalTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consensus_protocol': value.consensus_protocol,
        'runtime_host_protocol': value.runtime_host_protocol,
        'runtime_committee_protocol': value.runtime_committee_protocol,
    };
}


