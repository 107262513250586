"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.visitCall = exports.signUnverifiedTransaction = exports.proveAny = exports.proveMultisig = exports.proveSignature = exports.signAny = exports.deriveChainContext = exports.CALLFORMAT_ENCRYPTED_X25519DEOXYSII = exports.CALLFORMAT_PLAIN = exports.LATEST_TRANSACTION_VERSION = exports.SIGNATURE_CONTEXT_BASE = void 0;
const oasis = require("@oasisprotocol/client");
/**
 * Transaction signature domain separation context base.
 */
exports.SIGNATURE_CONTEXT_BASE = 'oasis-runtime-sdk/tx: v0';
/**
 * The latest transaction format version.
 */
exports.LATEST_TRANSACTION_VERSION = 1;
/**
 * Plain text call data.
 */
exports.CALLFORMAT_PLAIN = 0;
/**
 * Encrypted call data using X25519 for key exchange and Deoxys-II for symmetric encryption.
 */
exports.CALLFORMAT_ENCRYPTED_X25519DEOXYSII = 1;
async function deriveChainContext(runtimeID, consensusChainContext) {
    return oasis.misc.toHex(await oasis.hash.hash(oasis.misc.concat(runtimeID, oasis.misc.fromString(consensusChainContext))));
}
exports.deriveChainContext = deriveChainContext;
async function signAny(pk, signer, context, body) {
    if (pk.ed25519) {
        return await signer.sign(context, body);
    }
    else if (pk.secp256k1) {
        return await signer.sign(context, body);
    }
    else {
        throw new Error('unsupported public key type');
    }
}
exports.signAny = signAny;
async function proveSignature(spec, signer, context, body) {
    if (spec.ed25519) {
        return { signature: await signer.sign(context, body) };
    }
    else if (spec.secp256k1eth) {
        return { signature: await signer.sign(context, body) };
    }
    else {
        throw new Error('unsupported signature address specification type');
    }
}
exports.proveSignature = proveSignature;
async function proveMultisig(config, signerSet, context, body) {
    const signatureSet = new Array(config.signers.length);
    for (let i = 0; i < config.signers.length; i++) {
        if (signerSet[i]) {
            signatureSet[i] = await signAny(config.signers[i].public_key, signerSet[i], context, body);
        }
        else {
            signatureSet[i] = null;
        }
    }
    return { multisig: signatureSet };
}
exports.proveMultisig = proveMultisig;
async function proveAny(addressSpec, proofProvider, context, body) {
    if (addressSpec.signature) {
        return await proveSignature(addressSpec.signature, proofProvider, context, body);
    }
    else if (addressSpec.multisig) {
        return await proveMultisig(addressSpec.multisig, proofProvider, context, body);
    }
    else {
        throw new Error('unsupported address spec type');
    }
}
exports.proveAny = proveAny;
/**
 * @param proofProviders An array of providers matching the layout of the
 * transaction's signer info.
 */
async function signUnverifiedTransaction(proofProviders, runtimeID, consensusChainContext, transaction) {
    const chainContext = await deriveChainContext(runtimeID, consensusChainContext);
    const context = oasis.signature.combineChainContext(exports.SIGNATURE_CONTEXT_BASE, chainContext);
    const body = oasis.misc.toCBOR(transaction);
    const authProofs = new Array(transaction.ai.si.length);
    for (let i = 0; i < transaction.ai.si.length; i++) {
        authProofs[i] = await proveAny(transaction.ai.si[i].address_spec, proofProviders[i], context, body);
    }
    return [body, authProofs];
}
exports.signUnverifiedTransaction = signUnverifiedTransaction;
function visitCall(handlers, call) {
    if (handlers[call.method]) {
        handlers[call.method](call.body);
        return true;
    }
    return false;
}
exports.visitCall = visitCall;
