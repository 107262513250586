"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePolicyWrapper = exports.ERR_NO_SUCH_STATUS_CODE = exports.MODULE_NAME = exports.METHOD_UPDATE_POLICY = void 0;
const consensus = require("./consensus");
/**
 * MethodUpdatePolicy is the method name for policy updates.
 */
exports.METHOD_UPDATE_POLICY = 'keymanager.UpdatePolicy';
/**
 * ModuleName is a unique module name for the keymanager module.
 */
exports.MODULE_NAME = 'keymanager';
/**
 * ErrNoSuchStatus is the error returned when a key manager status does not
 * exist.
 */
exports.ERR_NO_SUCH_STATUS_CODE = 1;
function updatePolicyWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_UPDATE_POLICY);
}
exports.updatePolicyWrapper = updatePolicyWrapper;
