/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BlocksRow,
    BlocksRowFromJSON,
    BlocksRowToJSON,
} from '../models';

export interface GetBlocksListRequest {
    limit?: number;
    offset?: number;
    from?: number;
    to?: number;
    blockId?: Array<string>;
    blockLevel?: Array<number>;
}

/**
 * 
 */
export class BlocksApi extends runtime.BaseAPI {

    /**
     */
    async getBlocksListRaw(requestParameters: GetBlocksListRequest): Promise<runtime.ApiResponse<Array<BlocksRow>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.blockId) {
            queryParameters['block_id'] = requestParameters.blockId;
        }

        if (requestParameters.blockLevel) {
            queryParameters['block_level'] = requestParameters.blockLevel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data/blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BlocksRowFromJSON));
    }

    /**
     */
    async getBlocksList(requestParameters: GetBlocksListRequest): Promise<Array<BlocksRow>> {
        const response = await this.getBlocksListRaw(requestParameters);
        return await response.value();
    }

}
