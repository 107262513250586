/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountRewardStatsInfo,
    AccountRewardStatsInfoFromJSON,
    AccountRewardStatsInfoFromJSONTyped,
    AccountRewardStatsInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountRewardStatsResponse
 */
export interface AccountRewardStatsResponse {
    /**
     * 
     * @type {{ [key: string]: AccountRewardStatsInfo; }}
     * @memberof AccountRewardStatsResponse
     */
    stats: { [key: string]: AccountRewardStatsInfo; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountRewardStatsResponse
     */
    time: Array<number>;
}

export function AccountRewardStatsResponseFromJSON(json: any): AccountRewardStatsResponse {
    return AccountRewardStatsResponseFromJSONTyped(json, false);
}

export function AccountRewardStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRewardStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stats': (mapValues(json['stats'], AccountRewardStatsInfoFromJSON)),
        'time': json['time'],
    };
}

export function AccountRewardStatsResponseToJSON(value?: AccountRewardStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stats': (mapValues(value.stats, AccountRewardStatsInfoToJSON)),
        'time': value.time,
    };
}


