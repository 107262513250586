"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.visitTransaction = exports.TransactionWrapper = exports.hashSignedTransaction = exports.signSignedTransaction = exports.openSignedTransaction = exports.TRANSACTION_ERR_UPGRADE_PENDING = exports.TRANSACTION_ERR_GAS_PRICE_TOO_LOW_CODE = exports.TRANSACTION_ERR_INSUFFICIENT_FEE_BALANCE_CODE = exports.TRANSACTION_ERR_INVALID_NONCE_CODE = exports.TRANSACTION_MODULE_NAME = exports.ERR_INVALID_ARGUMENT_CODE = exports.ERR_DUPLICATE_TX_CODE = exports.ERR_UNSUPPORTED_CODE = exports.ERR_VERSION_NOT_FOUND_CODE = exports.ERR_OVERSIZED_TX_CODE = exports.ERR_NO_COMMITTED_BLOCKS_CODE = exports.MODULE_NAME = exports.TENDERMINT_BACKEND_NAME = exports.GAS_OP_TX_BYTE = exports.FEATURE_FULL_NODE = exports.FEATURE_SERVICES = exports.TRANSACTION_SIGNATURE_CONTEXT = exports.HEIGHT_LATEST = void 0;
const hash = require("./hash");
const misc = require("./misc");
const quantity = require("./quantity");
const signature = require("./signature");
/**
 * HeightLatest is the height that represents the most recent block height.
 */
exports.HEIGHT_LATEST = 0n;
/**
 * SignatureContext is the context used for signing transactions.
 */
exports.TRANSACTION_SIGNATURE_CONTEXT = 'oasis-core/consensus: tx';
/**
 * FeatureServices indicates support for communicating with consensus services.
 */
exports.FEATURE_SERVICES = 1 << 0;
/**
 * FeatureFullNode indicates that the consensus backend is independently fully verifying all
 * consensus-layer blocks.
 */
exports.FEATURE_FULL_NODE = 1 << 1;
/**
 * GasOpTxByte is the gas operation identifier for costing each transaction byte.
 */
exports.GAS_OP_TX_BYTE = 'tx_byte';
/**
 * BackendName is the consensus backend name.
 */
exports.TENDERMINT_BACKEND_NAME = 'tendermint';
/**
 * moduleName is the module name used for error definitions.
 */
exports.MODULE_NAME = 'consensus';
/**
 * ErrNoCommittedBlocks is the error returned when there are no committed
 * blocks and as such no state can be queried.
 */
exports.ERR_NO_COMMITTED_BLOCKS_CODE = 1;
/**
 * ErrOversizedTx is the error returned when the given transaction is too big to be processed.
 */
exports.ERR_OVERSIZED_TX_CODE = 2;
/**
 * ErrVersionNotFound is the error returned when the given version (height) cannot be found,
 * possibly because it was pruned.
 */
exports.ERR_VERSION_NOT_FOUND_CODE = 3;
/**
 * ErrUnsupported is the error returned when the given method is not supported by the consensus
 * backend.
 */
exports.ERR_UNSUPPORTED_CODE = 4;
/**
 * ErrDuplicateTx is the error returned when the transaction already exists in the mempool.
 */
exports.ERR_DUPLICATE_TX_CODE = 5;
/**
 * ErrInvalidArgument is the error returned when the request contains an invalid argument.
 */
exports.ERR_INVALID_ARGUMENT_CODE = 6;
/**
 * moduleName is the module name used for error definitions.
 */
exports.TRANSACTION_MODULE_NAME = 'consensus/transaction';
/**
 * ErrInvalidNonce is the error returned when a nonce is invalid.
 */
exports.TRANSACTION_ERR_INVALID_NONCE_CODE = 1;
/**
 * ErrInsufficientFeeBalance is the error returned when there is insufficient
 * balance to pay consensus fees.
 */
exports.TRANSACTION_ERR_INSUFFICIENT_FEE_BALANCE_CODE = 2;
/**
 * ErrGasPriceTooLow is the error returned when the gas price is too low.
 */
exports.TRANSACTION_ERR_GAS_PRICE_TOO_LOW_CODE = 3;
/**
 * ErrUpgradePending is the error returned when an upgrade is pending and the transaction thus
 * cannot be processed right now. The submitter should retry the transaction in this case.
 */
exports.TRANSACTION_ERR_UPGRADE_PENDING = 4;
async function openSignedTransaction(chainContext, signed) {
    const context = signature.combineChainContext(exports.TRANSACTION_SIGNATURE_CONTEXT, chainContext);
    return misc.fromCBOR(await signature.openSigned(context, signed));
}
exports.openSignedTransaction = openSignedTransaction;
async function signSignedTransaction(signer, chainContext, transaction) {
    const context = signature.combineChainContext(exports.TRANSACTION_SIGNATURE_CONTEXT, chainContext);
    return await signature.signSigned(signer, context, misc.toCBOR(transaction));
}
exports.signSignedTransaction = signSignedTransaction;
/**
 * This special hex-hash-of-the-CBOR-encoded signed transaction is useful for interoperability
 * with block explorers, so here's a special function for doing it.
 */
async function hashSignedTransaction(signed) {
    return misc.toHex(await hash.hash(misc.toCBOR(signed)));
}
exports.hashSignedTransaction = hashSignedTransaction;
class TransactionWrapper {
    constructor(method) {
        this.transaction = {
            nonce: 0n,
            fee: {
                amount: quantity.fromBigInt(0n),
                gas: 0n,
            },
            method,
        };
        this.signedTransaction = null;
    }
    setNonce(nonce) {
        this.transaction.nonce = nonce;
        return this;
    }
    setFeeAmount(amount) {
        this.transaction.fee.amount = amount;
        return this;
    }
    setFeeGas(gas) {
        this.transaction.fee.gas = gas;
        return this;
    }
    setBody(body) {
        this.transaction.body = body;
        return this;
    }
    async estimateGas(nic, signer) {
        return await nic.consensusEstimateGas({
            signer,
            transaction: this.transaction,
        });
    }
    async sign(signer, chainContext) {
        this.signedTransaction = await signSignedTransaction(signer, chainContext, this.transaction);
    }
    async hash() {
        return await hashSignedTransaction(this.signedTransaction);
    }
    async submit(nic) {
        await nic.consensusSubmitTx(this.signedTransaction);
    }
}
exports.TransactionWrapper = TransactionWrapper;
/**
 * Calls one of the handlers based on the given transaction method.
 * @param handlers Handlers, use an intersection of other modules'
 * `ConsensusTransactionHandlers` types to initialize the fields.
 * @param tx The transaction
 * @returns `true` if the transaction method matched one of the handlers
 */
function visitTransaction(handlers, tx) {
    if (handlers[tx.method]) {
        handlers[tx.method](tx.body);
        return true;
    }
    return false;
}
exports.visitTransaction = visitTransaction;
