/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuntimeEvmContractVerification,
    RuntimeEvmContractVerificationFromJSON,
    RuntimeEvmContractVerificationFromJSONTyped,
    RuntimeEvmContractVerificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuntimeEvmContract
 */
export interface RuntimeEvmContract {
    /**
     * The Oasis cryptographic hash of the transaction that created the smart contract.
     * Can be omitted for contracts that were created by another contract, as opposed
     * to a direct `Create` call.
     * @type {string}
     * @memberof RuntimeEvmContract
     */
    creation_tx?: string;
    /**
     * The Ethereum transaction hash of the transaction in `creation_tx`.
     * Encoded as a lowercase hex string.
     * @type {string}
     * @memberof RuntimeEvmContract
     */
    eth_creation_tx?: string;
    /**
     * The creation bytecode of the smart contract. This includes the constructor logic
     * and the constructor parameters. When run, this code generates the runtime bytecode.
     * Can be omitted for contracts that were created by another contract, as opposed
     * to a direct `Create` call.
     * @type {string}
     * @memberof RuntimeEvmContract
     */
    creation_bytecode?: string;
    /**
     * The runtime bytecode of the smart contract. This is the code stored on-chain that
     * describes a smart contract. Every contract has this info, but Nexus fetches
     * it separately, so the field may be missing for very fresh contracts (or if the fetching
     * process is stalled).
     * @type {string}
     * @memberof RuntimeEvmContract
     */
    runtime_bytecode?: string;
    /**
     * The total amount of gas used to create or call this contract.
     * @type {number}
     * @memberof RuntimeEvmContract
     */
    gas_used: number;
    /**
     * Additional information obtained from contract verification. Only available for smart
     * contracts that have been verified successfully by Sourcify.
     * @type {RuntimeEvmContractVerification}
     * @memberof RuntimeEvmContract
     */
    verification?: RuntimeEvmContractVerification;
}

export function RuntimeEvmContractFromJSON(json: any): RuntimeEvmContract {
    return RuntimeEvmContractFromJSONTyped(json, false);
}

export function RuntimeEvmContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeEvmContract {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creation_tx': !exists(json, 'creation_tx') ? undefined : json['creation_tx'],
        'eth_creation_tx': !exists(json, 'eth_creation_tx') ? undefined : json['eth_creation_tx'],
        'creation_bytecode': !exists(json, 'creation_bytecode') ? undefined : json['creation_bytecode'],
        'runtime_bytecode': !exists(json, 'runtime_bytecode') ? undefined : json['runtime_bytecode'],
        'gas_used': json['gas_used'],
        'verification': !exists(json, 'verification') ? undefined : RuntimeEvmContractVerificationFromJSON(json['verification']),
    };
}

export function RuntimeEvmContractToJSON(value?: RuntimeEvmContract | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creation_tx': value.creation_tx,
        'eth_creation_tx': value.eth_creation_tx,
        'creation_bytecode': value.creation_bytecode,
        'runtime_bytecode': value.runtime_bytecode,
        'gas_used': value.gas_used,
        'verification': RuntimeEvmContractVerificationToJSON(value.verification),
    };
}


