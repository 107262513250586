/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorAggStats
 */
export interface ValidatorAggStats {
    /**
     * The total voting power across all validators.
     * @type {number}
     * @memberof ValidatorAggStats
     */
    total_voting_power: number;
    /**
     * The total number of delegators in the network.
     * @type {number}
     * @memberof ValidatorAggStats
     */
    total_delegators: number;
    /**
     * The total amount of token staked to validators.
     * @type {string}
     * @memberof ValidatorAggStats
     */
    total_staked_balance: string;
}

export function ValidatorAggStatsFromJSON(json: any): ValidatorAggStats {
    return ValidatorAggStatsFromJSONTyped(json, false);
}

export function ValidatorAggStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorAggStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total_voting_power': json['total_voting_power'],
        'total_delegators': json['total_delegators'],
        'total_staked_balance': json['total_staked_balance'],
    };
}

export function ValidatorAggStatsToJSON(value?: ValidatorAggStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_voting_power': value.total_voting_power,
        'total_delegators': value.total_delegators,
        'total_staked_balance': value.total_staked_balance,
    };
}


