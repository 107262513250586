/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Chart,
    ChartFromJSON,
    ChartFromJSONTyped,
    ChartToJSON,
} from './';

/**
 * 
 * @export
 * @interface NetworkTrendResponse
 */
export interface NetworkTrendResponse {
    /**
     * 
     * @type {Array<Chart>}
     * @memberof NetworkTrendResponse
     */
    tx: Array<Chart>;
    /**
     * 
     * @type {Array<Chart>}
     * @memberof NetworkTrendResponse
     */
    escrow: Array<Chart>;
}

export function NetworkTrendResponseFromJSON(json: any): NetworkTrendResponse {
    return NetworkTrendResponseFromJSONTyped(json, false);
}

export function NetworkTrendResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkTrendResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tx': ((json['tx'] as Array<any>).map(ChartFromJSON)),
        'escrow': ((json['escrow'] as Array<any>).map(ChartFromJSON)),
    };
}

export function NetworkTrendResponseToJSON(value?: NetworkTrendResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tx': ((value.tx as Array<any>).map(ChartToJSON)),
        'escrow': ((value.escrow as Array<any>).map(ChartToJSON)),
    };
}


