"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signMultiSignedNode = exports.openMultiSignedNode = exports.signSignedEntity = exports.openSignedEntity = exports.IDENTITY_ERR_CERTIFICATE_ROTATION_FORBIDDEN_CODE = exports.IDENTITY_MODULE_NAME = exports.ERR_UNKNOWN_ERROR_CODE = exports.UNKNOWN_MODULE = exports.CODE_NO_ERROR = exports.LATEST_NODE_DESCRIPTOR_VERSION = exports.ENTITY_LATEST_DESCRIPTOR_VERSION = exports.INVALID_VERSION = exports.TEE_HARDWARE_RESERVED = exports.TEE_HARDWARE_INTEL_SGX = exports.TEE_HARDWARE_INVALID = exports.ROLE_STORAGE_RPC = exports.ROLE_CONSENSUS_RPC = exports.ROLE_VALIDATOR = exports.ROLE_KEY_MANAGER = exports.ROLE_COMPUTE_WORKER = void 0;
const misc = require("./misc");
const signature = require("./signature");
/**
 * RoleComputeWorker is the compute worker role.
 */
exports.ROLE_COMPUTE_WORKER = 1 << 0;
/**
 * RoleKeyManager is the the key manager role.
 */
exports.ROLE_KEY_MANAGER = 1 << 2;
/**
 * RoleValidator is the validator role.
 */
exports.ROLE_VALIDATOR = 1 << 3;
/**
 * RoleConsensusRPC is the public consensus RPC services worker role.
 */
exports.ROLE_CONSENSUS_RPC = 1 << 4;
/**
 * RoleStorageRPC is the public storage RPC services worker role.
 */
exports.ROLE_STORAGE_RPC = 1 << 5;
/**
 * TEEHardwareInvalid is a non-TEE implementation.
 */
exports.TEE_HARDWARE_INVALID = 0;
/**
 * TEEHardwareIntelSGX is an Intel SGX TEE implementation.
 */
exports.TEE_HARDWARE_INTEL_SGX = 1;
/**
 * TEEHardwareReserved is the first reserved hardware implementation
 * identifier. All equal or greater identifiers are reserved.
 */
exports.TEE_HARDWARE_RESERVED = exports.TEE_HARDWARE_INTEL_SGX + 1;
exports.INVALID_VERSION = 65536;
/**
 * LatestDescriptorVersion is the latest descriptor version that should be
 * used for all new descriptors. Using earlier versions may be rejected.
 */
exports.ENTITY_LATEST_DESCRIPTOR_VERSION = 2;
/**
 * LatestNodeDescriptorVersion is the latest node descriptor version that should be used for all
 * new descriptors. Using earlier versions may be rejected.
 */
exports.LATEST_NODE_DESCRIPTOR_VERSION = 1;
/**
 * CodeNoError is the reserved "no error" code.
 */
exports.CODE_NO_ERROR = 0;
/**
 * UnknownModule is the module name used when the module is unknown.
 */
exports.UNKNOWN_MODULE = 'unknown';
exports.ERR_UNKNOWN_ERROR_CODE = 1;
exports.IDENTITY_MODULE_NAME = 'identity';
/**
 * ErrCertificateRotationForbidden is returned by RotateCertificates if
 * TLS certificate rotation is forbidden.  This happens when rotation is
 * enabled and an existing TLS certificate was successfully loaded
 * (or a new one was generated and persisted to disk).
 */
exports.IDENTITY_ERR_CERTIFICATE_ROTATION_FORBIDDEN_CODE = 1;
async function openSignedEntity(context, signed) {
    return misc.fromCBOR(await signature.openSigned(context, signed));
}
exports.openSignedEntity = openSignedEntity;
async function signSignedEntity(signer, context, entity) {
    return await signature.signSigned(signer, context, misc.toCBOR(entity));
}
exports.signSignedEntity = signSignedEntity;
async function openMultiSignedNode(context, multiSigned) {
    return misc.fromCBOR(await signature.openMultiSigned(context, multiSigned));
}
exports.openMultiSignedNode = openMultiSignedNode;
async function signMultiSignedNode(signers, context, node) {
    return await signature.signMultiSigned(signers, context, misc.toCBOR(node));
}
exports.signMultiSignedNode = signMultiSignedNode;
