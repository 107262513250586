/* tslint:disable */
/* eslint-disable */
/**
 * 
 * This api document example is the Mainnet document, and the Testnet base URL is api.oasisscan.com/v2/testnet
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GovernanceProposalListResponse,
    GovernanceProposalListResponseFromJSON,
    GovernanceProposalListResponseToJSON,
    GovernanceProposalWithVotesResponse,
    GovernanceProposalWithVotesResponseFromJSON,
    GovernanceProposalWithVotesResponseToJSON,
    GovernanceVotesResponse,
    GovernanceVotesResponseFromJSON,
    GovernanceVotesResponseToJSON,
} from '../models';

export interface GovernanceProposalWithVotesHandlerRequest {
    id: number;
}

export interface GovernanceVotesHandlerRequest {
    page: number;
    size: number;
    proposalId?: number;
    validator?: string;
}

/**
 * 
 */
export class GovernanceApi extends runtime.BaseAPI {

    /**
     */
    async governanceProposalListHandlerRaw(): Promise<runtime.ApiResponse<GovernanceProposalListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/proposallist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceProposalListResponseFromJSON(jsonValue));
    }

    /**
     */
    async governanceProposalListHandler(): Promise<GovernanceProposalListResponse> {
        const response = await this.governanceProposalListHandlerRaw();
        return await response.value();
    }

    /**
     */
    async governanceProposalWithVotesHandlerRaw(requestParameters: GovernanceProposalWithVotesHandlerRequest): Promise<runtime.ApiResponse<GovernanceProposalWithVotesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling governanceProposalWithVotesHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/proposalwithvotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceProposalWithVotesResponseFromJSON(jsonValue));
    }

    /**
     */
    async governanceProposalWithVotesHandler(requestParameters: GovernanceProposalWithVotesHandlerRequest): Promise<GovernanceProposalWithVotesResponse> {
        const response = await this.governanceProposalWithVotesHandlerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async governanceVotesHandlerRaw(requestParameters: GovernanceVotesHandlerRequest): Promise<runtime.ApiResponse<GovernanceVotesResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling governanceVotesHandler.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling governanceVotesHandler.');
        }

        const queryParameters: any = {};

        if (requestParameters.proposalId !== undefined) {
            queryParameters['proposalId'] = requestParameters.proposalId;
        }

        if (requestParameters.validator !== undefined) {
            queryParameters['validator'] = requestParameters.validator;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/votes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceVotesResponseFromJSON(jsonValue));
    }

    /**
     */
    async governanceVotesHandler(requestParameters: GovernanceVotesHandlerRequest): Promise<GovernanceVotesResponse> {
        const response = await this.governanceVotesHandlerRaw(requestParameters);
        return await response.value();
    }

}
