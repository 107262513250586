"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleEventHandler = exports.Wrapper = exports.METHOD_SIMULATE_CALL = exports.METHOD_BALANCE = exports.METHOD_CODE = exports.METHOD_STORAGE = exports.METHOD_CALL = exports.METHOD_CREATE = exports.EVENT_LOG_CODE = exports.ERROR_SIMULATION_TOO_EXPENSIVE = exports.ERROR_REVERTED_CODE = exports.ERROR_FORBIDDEN_CODE = exports.ERR_INSUFFICIENT_BALANCE_CODE = exports.ERR_GAS_LIMIT_TOO_LOW_CODE = exports.ERR_FEE_OVERFLOW_CODE = exports.ERR_INVALID_SIGNER_TYPE_CODE = exports.ERR_EXECUTION_FAILED_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'evm';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
exports.ERR_EXECUTION_FAILED_CODE = 2;
exports.ERR_INVALID_SIGNER_TYPE_CODE = 3;
exports.ERR_FEE_OVERFLOW_CODE = 4;
exports.ERR_GAS_LIMIT_TOO_LOW_CODE = 5;
exports.ERR_INSUFFICIENT_BALANCE_CODE = 6;
exports.ERROR_FORBIDDEN_CODE = 7;
exports.ERROR_REVERTED_CODE = 8;
exports.ERROR_SIMULATION_TOO_EXPENSIVE = 8;
exports.EVENT_LOG_CODE = 1;
// Callable methods.
exports.METHOD_CREATE = 'evm.Create';
exports.METHOD_CALL = 'evm.Call';
// Queries.
exports.METHOD_STORAGE = 'evm.Storage';
exports.METHOD_CODE = 'evm.Code';
exports.METHOD_BALANCE = 'evm.Balance';
exports.METHOD_SIMULATE_CALL = 'evm.SimulateCall';
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    callCreate() {
        return this.call(exports.METHOD_CREATE);
    }
    callCall() {
        return this.call(exports.METHOD_CALL);
    }
    queryStorage() {
        return this.query(exports.METHOD_STORAGE);
    }
    queryCode() {
        return this.query(exports.METHOD_CODE);
    }
    queryBalance() {
        return this.query(exports.METHOD_BALANCE);
    }
    querySimulateCall() {
        return this.query(exports.METHOD_SIMULATE_CALL);
    }
}
exports.Wrapper = Wrapper;
function moduleEventHandler(codes) {
    return [exports.MODULE_NAME, codes];
}
exports.moduleEventHandler = moduleEventHandler;
