/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Block,
    BlockFromJSON,
    BlockFromJSONTyped,
    BlockToJSON,
} from './';

/**
 * 
 * @export
 * @interface BlockListAllOf
 */
export interface BlockListAllOf {
    /**
     * 
     * @type {Array<Block>}
     * @memberof BlockListAllOf
     */
    blocks: Array<Block>;
}

export function BlockListAllOfFromJSON(json: any): BlockListAllOf {
    return BlockListAllOfFromJSONTyped(json, false);
}

export function BlockListAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockListAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': ((json['blocks'] as Array<any>).map(BlockFromJSON)),
    };
}

export function BlockListAllOfToJSON(value?: BlockListAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': ((value.blocks as Array<any>).map(BlockToJSON)),
    };
}


