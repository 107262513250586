"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressFromRuntimeID = exports.addressFromPublicKey = exports.TOKEN_ERR_INVALID_TOKEN_VALUE_EXPONENT_CODE = exports.TOKEN_MODULE_NAME = exports.ERR_BALANCE_TOO_LOW_CODE = exports.ERR_UNDER_MIN_TRANSFER_AMOUNT_CODE = exports.ERR_UNDER_MIN_DELEGATION_AMOUNT_CODE = exports.ERR_TOO_MANY_ALLOWANCES_CODE = exports.ERR_INVALID_THRESHOLD_CODE = exports.ERR_FORBIDDEN_CODE = exports.ERR_INSUFFICIENT_STAKE_CODE = exports.ERR_INSUFFICIENT_BALANCE_CODE = exports.ERR_INVALID_SIGNATURE_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = exports.GAS_OP_WITHDRAW = exports.GAS_OP_ALLOW = exports.GAS_OP_AMEND_COMMISSION_SCHEDULE = exports.GAS_OP_RECLAIM_ESCROW = exports.GAS_OP_ADD_ESCROW = exports.GAS_OP_BURN = exports.GAS_OP_TRANSFER = exports.SLASH_RUNTIME_LIVENESS = exports.SLASH_RUNTIME_EQUIVOCATION = exports.SLASH_RUNTIME_INCORRECT_RESULTS = exports.SLASH_CONSENSUS_LIGHT_CLIENT_ATTACK = exports.SLASH_BEACON_NONPARTICIPATION = exports.SLASH_BEACON_INVALID_REVEAL = exports.SLASH_BEACON_INVALID_COMMIT = exports.SLASH_CONSENSUS_EQUIVOCATION = exports.KIND_MAX = exports.KIND_RUNTIME_KEY_MANAGER = exports.KIND_RUNTIME_COMPUTE = exports.KIND_NODE_KEY_MANAGER = exports.KIND_NODE_STORAGE = exports.KIND_NODE_COMPUTE = exports.KIND_NODE_VALIDATOR = exports.KIND_ENTITY = exports.METHOD_WITHDRAW = exports.METHOD_ALLOW = exports.METHOD_AMEND_COMMISSION_SCHEDULE = exports.METHOD_RECLAIM_ESCROW = exports.METHOD_ADD_ESCROW = exports.METHOD_BURN = exports.METHOD_TRANSFER = exports.ADDRESS_PREFIX = exports.ADDRESS_RUNTIME_V0_CONTEXT_VERSION = exports.ADDRESS_RUNTIME_V0_CONTEXT_IDENTIFIER = exports.ADDRESS_V0_CONTEXT_VERSION = exports.ADDRESS_V0_CONTEXT_IDENTIFIER = void 0;
exports.withdrawWrapper = exports.allowWrapper = exports.amendCommissionScheduleWrapper = exports.reclaimEscrowWrapper = exports.addEscrowWrapper = exports.burnWrapper = exports.transferWrapper = exports.governanceDepositsAddress = exports.feeAccumulatorAddress = exports.commonPoolAddress = exports.addressFromBech32 = exports.addressToBech32 = void 0;
const address = require("./address");
const consensus = require("./consensus");
const misc = require("./misc");
/**
 * AddressV0Context is the unique context for v0 staking account addresses.
 */
exports.ADDRESS_V0_CONTEXT_IDENTIFIER = 'oasis-core/address: staking';
/**
 * AddressV0Context is the unique context for v0 staking account addresses.
 */
exports.ADDRESS_V0_CONTEXT_VERSION = 0;
/**
 * AddressRuntimeV0Context is the unique context for v0 runtime account addresses.
 */
exports.ADDRESS_RUNTIME_V0_CONTEXT_IDENTIFIER = 'oasis-core/address: runtime';
/**
 * AddressRuntimeV0Context is the unique context for v0 runtime account addresses.
 */
exports.ADDRESS_RUNTIME_V0_CONTEXT_VERSION = 0;
/**
 * AddressBech32HRP is the unique human readable part of Bech32 encoded
 * staking account addresses.
 */
exports.ADDRESS_PREFIX = 'oasis';
/**
 * MethodTransfer is the method name for transfers.
 */
exports.METHOD_TRANSFER = 'staking.Transfer';
/**
 * MethodBurn is the method name for burns.
 */
exports.METHOD_BURN = 'staking.Burn';
/**
 * MethodAddEscrow is the method name for escrows.
 */
exports.METHOD_ADD_ESCROW = 'staking.AddEscrow';
/**
 * MethodReclaimEscrow is the method name for escrow reclamations.
 */
exports.METHOD_RECLAIM_ESCROW = 'staking.ReclaimEscrow';
/**
 * MethodAmendCommissionSchedule is the method name for amending commission schedules.
 */
exports.METHOD_AMEND_COMMISSION_SCHEDULE = 'staking.AmendCommissionSchedule';
/**
 * MethodAllow is the method name for setting a beneficiary allowance.
 */
exports.METHOD_ALLOW = 'staking.Allow';
/**
 * MethodWithdraw is the method name for
 */
exports.METHOD_WITHDRAW = 'staking.Withdraw';
exports.KIND_ENTITY = 0;
exports.KIND_NODE_VALIDATOR = 1;
exports.KIND_NODE_COMPUTE = 2;
exports.KIND_NODE_STORAGE = 3;
exports.KIND_NODE_KEY_MANAGER = 4;
exports.KIND_RUNTIME_COMPUTE = 5;
exports.KIND_RUNTIME_KEY_MANAGER = 6;
exports.KIND_MAX = exports.KIND_RUNTIME_KEY_MANAGER;
/**
 * SlashConsensusEquivocation is slashing due to equivocation.
 */
exports.SLASH_CONSENSUS_EQUIVOCATION = 0x00;
/**
 * SlashBeaconInvalidCommit is slashing due to invalid commit behavior.
 */
exports.SLASH_BEACON_INVALID_COMMIT = 0x01;
/**
 * SlashBeaconInvalidReveal is slashing due to invalid reveal behavior.
 */
exports.SLASH_BEACON_INVALID_REVEAL = 0x02;
/**
 * SlashBeaconNonparticipation is slashing due to nonparticipation.
 */
exports.SLASH_BEACON_NONPARTICIPATION = 0x03;
/**
 * SlashConsensusLightClientAttack is slashing due to light client attacks.
 */
exports.SLASH_CONSENSUS_LIGHT_CLIENT_ATTACK = 0x04;
/**
 * SlashRuntimeIncorrectResults is slashing due to submission of incorrect
 * results in runtime executor commitments.
 */
exports.SLASH_RUNTIME_INCORRECT_RESULTS = 0x80;
/**
 * SlashRuntimeEquivocation is slashing due to signing two different
 * executor commits or proposed batches for the same round.
 */
exports.SLASH_RUNTIME_EQUIVOCATION = 0x81;
/**
 * SlashRuntimeLiveness is slashing due to not doing the required work.
 */
exports.SLASH_RUNTIME_LIVENESS = 0x82;
/**
 * GasOpTransfer is the gas operation identifier for transfer.
 */
exports.GAS_OP_TRANSFER = 'transfer';
/**
 * GasOpBurn is the gas operation identifier for burn.
 */
exports.GAS_OP_BURN = 'burn';
/**
 * GasOpAddEscrow is the gas operation identifier for add escrow.
 */
exports.GAS_OP_ADD_ESCROW = 'add_escrow';
/**
 * GasOpReclaimEscrow is the gas operation identifier for reclaim escrow.
 */
exports.GAS_OP_RECLAIM_ESCROW = 'reclaim_escrow';
/**
 * GasOpAmendCommissionSchedule is the gas operation identifier for amend commission schedule.
 */
exports.GAS_OP_AMEND_COMMISSION_SCHEDULE = 'amend_commission_schedule';
/**
 * GasOpAllow is the gas operation identifier for allow.
 */
exports.GAS_OP_ALLOW = 'allow';
/**
 * GasOpWithdraw is the gas operation identifier for withdraw.
 */
exports.GAS_OP_WITHDRAW = 'withdraw';
/**
 * ModuleName is a unique module name for the staking module.
 */
exports.MODULE_NAME = 'staking';
/**
 * ErrInvalidArgument is the error returned on malformed arguments.
 */
exports.ERR_INVALID_ARGUMENT_CODE = 1;
/**
 * ErrInvalidSignature is the error returned on invalid signature.
 */
exports.ERR_INVALID_SIGNATURE_CODE = 2;
/**
 * ErrInsufficientBalance is the error returned when an operation
 * fails due to insufficient balance.
 */
exports.ERR_INSUFFICIENT_BALANCE_CODE = 3;
/**
 * ErrInsufficientStake is the error returned when an operation fails
 * due to insufficient stake.
 */
exports.ERR_INSUFFICIENT_STAKE_CODE = 4;
/**
 * ErrForbidden is the error returned when an operation is forbidden by
 * policy.
 */
exports.ERR_FORBIDDEN_CODE = 5;
/**
 * ErrInvalidThreshold is the error returned when an invalid threshold kind
 * is specified in a query.
 */
exports.ERR_INVALID_THRESHOLD_CODE = 6;
/**
 * ErrTooManyAllowances is the error returned when the number of allowances per account would
 * exceed the maximum allowed number.
 */
exports.ERR_TOO_MANY_ALLOWANCES_CODE = 7;
/**
 * ErrUnderMinDelegationAmount is the error returned when the given escrow
 * amount is lower than the minimum delegation amount specified in the
 * consensus parameters.
 */
exports.ERR_UNDER_MIN_DELEGATION_AMOUNT_CODE = 8;
/**
 * ErrUnderMinTransferAmount is the error returned when the given transfer
 * or burn or withdrawal amount is lower than the minimum transfer amount
 * specified in the consensus parameters.
 */
exports.ERR_UNDER_MIN_TRANSFER_AMOUNT_CODE = 9;
/**
 * ErrBalanceTooLow is the error returned when an account's balance is
 * below the minimum allowed amount.
 */
exports.ERR_BALANCE_TOO_LOW_CODE = 10;
/**
 * ModuleName is a unique module name for the staking/token module.
 */
exports.TOKEN_MODULE_NAME = 'staking/token';
/**
 * ErrInvalidTokenValueExponent is the error returned when an invalid token's
 * value base-10 exponent is specified.
 */
exports.TOKEN_ERR_INVALID_TOKEN_VALUE_EXPONENT_CODE = 1;
async function addressFromPublicKey(pk) {
    return await address.fromData(exports.ADDRESS_V0_CONTEXT_IDENTIFIER, exports.ADDRESS_V0_CONTEXT_VERSION, pk);
}
exports.addressFromPublicKey = addressFromPublicKey;
async function addressFromRuntimeID(id) {
    return await address.fromData(exports.ADDRESS_RUNTIME_V0_CONTEXT_IDENTIFIER, exports.ADDRESS_RUNTIME_V0_CONTEXT_VERSION, id);
}
exports.addressFromRuntimeID = addressFromRuntimeID;
function addressToBech32(addr) {
    return address.toBech32(exports.ADDRESS_PREFIX, addr);
}
exports.addressToBech32 = addressToBech32;
function addressFromBech32(str) {
    return address.fromBech32(exports.ADDRESS_PREFIX, str);
}
exports.addressFromBech32 = addressFromBech32;
/**
 * CommonPoolAddress is the common pool address.
 * The address is reserved to prevent it being accidentally used in the actual ledger.
 */
async function commonPoolAddress() {
    return await addressFromPublicKey(misc.fromHex('1abe11edc001ffffffffffffffffffffffffffffffffffffffffffffffffffff'));
}
exports.commonPoolAddress = commonPoolAddress;
/**
 * FeeAccumulatorAddress is the per-block fee accumulator address.
 * It holds all fees from txs in a block which are later disbursed to validators appropriately.
 * The address is reserved to prevent it being accidentally used in the actual ledger.
 */
async function feeAccumulatorAddress() {
    return await addressFromPublicKey(misc.fromHex('1abe11edfeeaccffffffffffffffffffffffffffffffffffffffffffffffffff'));
}
exports.feeAccumulatorAddress = feeAccumulatorAddress;
/**
 * GovernanceDepositsAddress is the governance deposits address.
 * This address is reserved to prevent it from being accidentally used in the actual ledger.
 */
async function governanceDepositsAddress() {
    return await addressFromPublicKey(misc.fromHex('1abe11eddeaccfffffffffffffffffffffffffffffffffffffffffffffffffff'));
}
exports.governanceDepositsAddress = governanceDepositsAddress;
function transferWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_TRANSFER);
}
exports.transferWrapper = transferWrapper;
function burnWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_BURN);
}
exports.burnWrapper = burnWrapper;
function addEscrowWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_ADD_ESCROW);
}
exports.addEscrowWrapper = addEscrowWrapper;
function reclaimEscrowWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_RECLAIM_ESCROW);
}
exports.reclaimEscrowWrapper = reclaimEscrowWrapper;
function amendCommissionScheduleWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_AMEND_COMMISSION_SCHEDULE);
}
exports.amendCommissionScheduleWrapper = amendCommissionScheduleWrapper;
function allowWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_ALLOW);
}
exports.allowWrapper = allowWrapper;
function withdrawWrapper() {
    return new consensus.TransactionWrapper(exports.METHOD_WITHDRAW);
}
exports.withdrawWrapper = withdrawWrapper;
