"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.visitMessage = exports.NaclSigner = exports.BlindContextSigner = exports.signMultiSigned = exports.openMultiSigned = exports.signSigned = exports.openSigned = exports.verify = exports.prepareSignerMessage = exports.combineChainContext = exports.CHAIN_CONTEXT_SEPARATOR = void 0;
const nacl = require("tweetnacl");
const hash = require("./hash");
const misc = require("./misc");
exports.CHAIN_CONTEXT_SEPARATOR = ' for chain ';
function combineChainContext(context, chainContext) {
    return `${context}${exports.CHAIN_CONTEXT_SEPARATOR}${chainContext}`;
}
exports.combineChainContext = combineChainContext;
async function prepareSignerMessage(context, message) {
    return await hash.hash(misc.concat(misc.fromString(context), message));
}
exports.prepareSignerMessage = prepareSignerMessage;
async function verify(publicKey, context, message, signature) {
    const signerMessage = await prepareSignerMessage(context, message);
    const sigOk = nacl.sign.detached.verify(signerMessage, signature, publicKey);
    return sigOk;
}
exports.verify = verify;
async function openSigned(context, signed) {
    const sigOk = await verify(signed.signature.public_key, context, signed.untrusted_raw_value, signed.signature.signature);
    if (!sigOk)
        throw new Error('signature verification failed');
    return signed.untrusted_raw_value;
}
exports.openSigned = openSigned;
async function signSigned(signer, context, rawValue) {
    return {
        untrusted_raw_value: rawValue,
        signature: {
            public_key: signer.public(),
            signature: await signer.sign(context, rawValue),
        },
    };
}
exports.signSigned = signSigned;
async function openMultiSigned(context, multiSigned) {
    const signerMessage = await prepareSignerMessage(context, multiSigned.untrusted_raw_value);
    for (const signature of multiSigned.signatures) {
        const sigOk = nacl.sign.detached.verify(signerMessage, signature.signature, signature.public_key);
        if (!sigOk)
            throw new Error('signature verification failed');
    }
    return multiSigned.untrusted_raw_value;
}
exports.openMultiSigned = openMultiSigned;
async function signMultiSigned(signers, context, rawValue) {
    const signatures = [];
    for (const signer of signers) {
        signatures.push({
            public_key: signer.public(),
            signature: await signer.sign(context, rawValue),
        });
    }
    return {
        untrusted_raw_value: rawValue,
        signatures: signatures,
    };
}
exports.signMultiSigned = signMultiSigned;
class BlindContextSigner {
    constructor(signer) {
        this.signer = signer;
    }
    public() {
        return this.signer.public();
    }
    async sign(context, message) {
        const signerMessage = await prepareSignerMessage(context, message);
        return await this.signer.sign(signerMessage);
    }
}
exports.BlindContextSigner = BlindContextSigner;
/**
 * An in-memory signer based on tweetnacl. We've included this for development.
 */
class NaclSigner {
    constructor(key, note) {
        if (note !== 'this key is not important')
            throw new Error('insecure signer implementation');
        this.key = key;
    }
    /**
     * Generate a keypair from a random seed
     * @param note Set to 'this key is not important' to acknowledge the risks
     * @returns Instance of NaclSigner
     */
    static fromRandom(note) {
        const secret = new Uint8Array(32);
        crypto.getRandomValues(secret);
        return NaclSigner.fromSeed(secret, note);
    }
    /**
     * Instanciate from a given secret
     * @param secret 64 bytes ed25519 secret (h) that will be used to sign messages
     * @param note Set to 'this key is not important' to acknowledge the risks
     * @returns Instance of NaclSigner
     */
    static fromSecret(secret, note) {
        const key = nacl.sign.keyPair.fromSecretKey(secret);
        return new NaclSigner(key, note);
    }
    /**
     * Instanciate from a given seed
     * @param seed 32 bytes ed25519 seed (k) that will deterministically generate a private key
     * @param note Set to 'this key is not important' to acknowledge the risks
     * @returns Instance of NaclSigner
     */
    static fromSeed(seed, note) {
        const key = nacl.sign.keyPair.fromSeed(seed);
        return new NaclSigner(key, note);
    }
    /**
     * Returns the 32 bytes public key of this key pair
     * @returns Public key
     */
    public() {
        return this.key.publicKey;
    }
    /**
     * Signs the given message
     * @param message Bytes to sign
     * @returns Signed message
     */
    async sign(message) {
        return nacl.sign.detached(message, this.key.secretKey);
    }
}
exports.NaclSigner = NaclSigner;
/**
 * Calls one of the handlers based on the given context.
 * @param handlers Handlers, use an intersection of other modules'
 * `SignatureMessageHandlers*` types to initialize the fields.
 * @param context The context string as would be given to `ContextSigner.sign`
 * @param message The messsage as would be given to `ContextSigner.sign`
 * @returns `true` if the context matched one of the handlers
 */
function visitMessage(handlers, context, message) {
    // This doesn't support dynamic suffixes.
    {
        const parts = context.split(exports.CHAIN_CONTEXT_SEPARATOR);
        if (parts.length === 2) {
            const [context2, chainContext] = parts;
            if (handlers.withChainContext?.[context2]) {
                handlers.withChainContext[context2](chainContext, misc.fromCBOR(message));
                return true;
            }
            return false;
        }
    }
    {
        if (handlers.bare?.[context]) {
            handlers.bare[context](misc.fromCBOR(message));
            return true;
        }
        return false;
    }
}
exports.visitMessage = visitMessage;
