/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDerivationContext,
    AddressDerivationContextFromJSON,
    AddressDerivationContextFromJSONTyped,
    AddressDerivationContextToJSON,
} from './';

/**
 * The data from which a consensus-style address (`oasis1...`)
 * was derived. Notably, for EVM runtimes like Sapphire,
 * this links the Oasis address and the Ethereum address.
 * 
 * Oasis addresses are derived from a piece of data, such as an ed25519
 * public key or an Ethereum address. For example, [this](https://github.com/oasisprotocol/oasis-sdk/blob/b37e6da699df331f5a2ac62793f8be099c68469c/client-sdk/go/helpers/address.go#L90-L91)
 * is how an Ethereum is converted to an Oasis address. The type of underlying data usually also
 * determines how the signatuers for this address are verified.
 * 
 * Consensus supports only "staking addresses" (`context="oasis-core/address: staking"`
 * below; always ed25519-backed).
 * Runtimes support all types. This means that every consensus address is also
 * valid in every runtime. For example, in EVM runtimes, you can use staking
 * addresses, but only with Oasis tools (e.g. a wallet); EVM contracts such as
 * ERC20 tokens or tools such as Metamask cannot interact with staking addresses.
 * @export
 * @interface AddressPreimage
 */
export interface AddressPreimage {
    /**
     * The method by which the Oasis address was derived from `address_data`.
     * @type {AddressDerivationContext}
     * @memberof AddressPreimage
     */
    context: AddressDerivationContext;
    /**
     * Version of the `context`.
     * @type {number}
     * @memberof AddressPreimage
     */
    context_version?: number;
    /**
     * The base64-encoded data from which the Oasis address was derived.
     * When `context = "oasis-runtime-sdk/address: secp256k1eth"`, this
     * is the Ethereum address (in base64, not hex!).
     * @type {string}
     * @memberof AddressPreimage
     */
    address_data: string;
}

export function AddressPreimageFromJSON(json: any): AddressPreimage {
    return AddressPreimageFromJSONTyped(json, false);
}

export function AddressPreimageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressPreimage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': AddressDerivationContextFromJSON(json['context']),
        'context_version': !exists(json, 'context_version') ? undefined : json['context_version'],
        'address_data': json['address_data'],
    };
}

export function AddressPreimageToJSON(value?: AddressPreimage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': AddressDerivationContextToJSON(value.context),
        'context_version': value.context_version,
        'address_data': value.address_data,
    };
}


