/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountStats,
    AccountStatsFromJSON,
    AccountStatsFromJSONTyped,
    AccountStatsToJSON,
    AddressPreimage,
    AddressPreimageFromJSON,
    AddressPreimageFromJSONTyped,
    AddressPreimageToJSON,
    RuntimeEvmBalance,
    RuntimeEvmBalanceFromJSON,
    RuntimeEvmBalanceFromJSONTyped,
    RuntimeEvmBalanceToJSON,
    RuntimeEvmContract,
    RuntimeEvmContractFromJSON,
    RuntimeEvmContractFromJSONTyped,
    RuntimeEvmContractToJSON,
    RuntimeSdkBalance,
    RuntimeSdkBalanceFromJSON,
    RuntimeSdkBalanceFromJSONTyped,
    RuntimeSdkBalanceToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuntimeAccount
 */
export interface RuntimeAccount {
    /**
     * The staking address for this account.
     * @type {string}
     * @memberof RuntimeAccount
     */
    address: string;
    /**
     * 
     * @type {AddressPreimage}
     * @memberof RuntimeAccount
     */
    address_preimage?: AddressPreimage;
    /**
     * The balance(s) of this account in this runtime. Most runtimes use only one denomination, and thus
     * produce only one balance here. These balances do not include "layer (n+1) tokens", i.e. tokens
     * managed by smart contracts deployed in this runtime. For example, in EVM-compatible runtimes,
     * this does not include ERC-20 tokens
     * @type {Array<RuntimeSdkBalance>}
     * @memberof RuntimeAccount
     */
    balances: Array<RuntimeSdkBalance>;
    /**
     * Data on the EVM smart contract associated with this account address. Only present for accounts
     * that represent a smart contract on EVM.
     * @type {RuntimeEvmContract}
     * @memberof RuntimeAccount
     */
    evm_contract?: RuntimeEvmContract;
    /**
     * The balances of this account in each runtime, as managed by EVM smart contracts (notably, ERC-20).
     * NOTE: This field is limited to 1000 entries. If you need more, please let us know in a GitHub issue.
     * @type {Array<RuntimeEvmBalance>}
     * @memberof RuntimeAccount
     */
    evm_balances: Array<RuntimeEvmBalance>;
    /**
     * 
     * @type {AccountStats}
     * @memberof RuntimeAccount
     */
    stats: AccountStats;
}

export function RuntimeAccountFromJSON(json: any): RuntimeAccount {
    return RuntimeAccountFromJSONTyped(json, false);
}

export function RuntimeAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'address_preimage': !exists(json, 'address_preimage') ? undefined : AddressPreimageFromJSON(json['address_preimage']),
        'balances': ((json['balances'] as Array<any>).map(RuntimeSdkBalanceFromJSON)),
        'evm_contract': !exists(json, 'evm_contract') ? undefined : RuntimeEvmContractFromJSON(json['evm_contract']),
        'evm_balances': ((json['evm_balances'] as Array<any>).map(RuntimeEvmBalanceFromJSON)),
        'stats': AccountStatsFromJSON(json['stats']),
    };
}

export function RuntimeAccountToJSON(value?: RuntimeAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'address_preimage': AddressPreimageToJSON(value.address_preimage),
        'balances': ((value.balances as Array<any>).map(RuntimeSdkBalanceToJSON)),
        'evm_contract': RuntimeEvmContractToJSON(value.evm_contract),
        'evm_balances': ((value.evm_balances as Array<any>).map(RuntimeEvmBalanceToJSON)),
        'stats': AccountStatsToJSON(value.stats),
    };
}


