/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsensusTxMethod {
    BeaconPvssCommit = 'beacon.PVSSCommit',
    BeaconPvssReveal = 'beacon.PVSSReveal',
    BeaconVrfProve = 'beacon.VRFProve',
    ConsensusMeta = 'consensus.Meta',
    GovernanceCastVote = 'governance.CastVote',
    GovernanceSubmitProposal = 'governance.SubmitProposal',
    KeymanagerPublishEphemeralSecret = 'keymanager.PublishEphemeralSecret',
    KeymanagerPublishMasterSecret = 'keymanager.PublishMasterSecret',
    KeymanagerUpdatePolicy = 'keymanager.UpdatePolicy',
    RegistryDeregisterEntity = 'registry.DeregisterEntity',
    RegistryProveFreshness = 'registry.ProveFreshness',
    RegistryRegisterEntity = 'registry.RegisterEntity',
    RegistryRegisterNode = 'registry.RegisterNode',
    RegistryRegisterRuntime = 'registry.RegisterRuntime',
    RegistryUnfreezeNode = 'registry.UnfreezeNode',
    RoothashEvidence = 'roothash.Evidence',
    RoothashExecutorCommit = 'roothash.ExecutorCommit',
    RoothashExecutorProposerTimeout = 'roothash.ExecutorProposerTimeout',
    RoothashSubmitMsg = 'roothash.SubmitMsg',
    StakingAddEscrow = 'staking.AddEscrow',
    StakingAllow = 'staking.Allow',
    StakingAmendCommissionSchedule = 'staking.AmendCommissionSchedule',
    StakingBurn = 'staking.Burn',
    StakingReclaimEscrow = 'staking.ReclaimEscrow',
    StakingTransfer = 'staking.Transfer',
    StakingWithdraw = 'staking.Withdraw',
    KeymanagerChurpApply = 'keymanager/churp.Apply',
    KeymanagerChurpConfirm = 'keymanager/churp.Confirm',
    KeymanagerChurpCreate = 'keymanager/churp.Create',
    KeymanagerChurpUpdate = 'keymanager/churp.Update',
    VaultAuthorizeAction = 'vault.AuthorizeAction',
    VaultCancelAction = 'vault.CancelAction',
    VaultCreate = 'vault.Create'
}

export function ConsensusTxMethodFromJSON(json: any): ConsensusTxMethod {
    return ConsensusTxMethodFromJSONTyped(json, false);
}

export function ConsensusTxMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsensusTxMethod {
    return json as ConsensusTxMethod;
}

export function ConsensusTxMethodToJSON(value?: ConsensusTxMethod | null): any {
    return value as any;
}

