"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.concat = exports.fromString = exports.toStringUTF8 = exports.fromBase64 = exports.toBase64 = exports.fromHex = exports.toHex = exports.fromCBOR = exports.toCBOR = void 0;
const cborg = require("cborg");
const HEX_DIGITS = '0123456789abcdef';
// oasis-core routinely uses maps with non-string keys, e.g. in a staking Delegations response. We
// can't pick and choose which CBOR maps to decode into objects and which to decode in to Maps, so
// walk through the data after decoding and find any string-keys-only and recreate them as objects.
function objsFromMaps(v) {
    if (v instanceof Map) {
        let keysCompatible = true;
        for (const key of v.keys()) {
            if (typeof key !== 'string') {
                keysCompatible = false;
                break;
            }
        }
        if (v.size > 0 && keysCompatible) {
            // Recreate as an object.
            const o = {};
            for (const [key, val] of v) {
                o[key] = objsFromMaps(val);
            }
            return o;
        }
        else {
            // Leave as a Map. We'd miss empty structs, but we wouldn't dare use such a thing,
            // would we?
            const m = new Map();
            for (const [key, val] of v) {
                m.set(objsFromMaps(key), objsFromMaps(val));
            }
            return m;
        }
    }
    else if (v instanceof Array) {
        const a = [];
        for (const elem of v) {
            a.push(objsFromMaps(elem));
        }
        return a;
    }
    else {
        return v;
    }
}
function toCBOR(v) {
    return cborg.encode(v);
}
exports.toCBOR = toCBOR;
function fromCBOR(u8) {
    // oasis-core uses a special case to unmarshal an empty byte string to `nil`.
    if (!u8.length)
        return undefined;
    return objsFromMaps(cborg.decode(u8, { useMaps: true }));
}
exports.fromCBOR = fromCBOR;
function toHex(u8) {
    let hex = '';
    for (const b of u8) {
        hex += HEX_DIGITS[b >>> 4] + HEX_DIGITS[b & 0xf];
    }
    return hex;
}
exports.toHex = toHex;
function fromHex(hex) {
    let byteLength = hex.length >> 1;
    const u8 = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength; i++) {
        u8[i] = parseInt(hex.substr(2 * i, 2), 16);
    }
    return u8;
}
exports.fromHex = fromHex;
function toBase64(u8) {
    return btoa(String.fromCharCode(...u8));
}
exports.toBase64 = toBase64;
function fromBase64(base64) {
    const binaryStr = atob(base64);
    const byteLength = binaryStr.length;
    const u8 = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength; i++) {
        u8[i] = binaryStr.charCodeAt(i);
    }
    return u8;
}
exports.fromBase64 = fromBase64;
function toStringUTF8(u8) {
    return new TextDecoder().decode(u8);
}
exports.toStringUTF8 = toStringUTF8;
function fromString(str) {
    return new TextEncoder().encode(str);
}
exports.fromString = fromString;
function concat(...parts) {
    let length = 0;
    for (const part of parts) {
        length += part.length;
    }
    let result = new Uint8Array(length);
    let pos = 0;
    for (const part of parts) {
        result.set(part, pos);
        pos += part.length;
    }
    return result;
}
exports.concat = concat;
