/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActiveAccounts,
    ActiveAccountsFromJSON,
    ActiveAccountsFromJSONTyped,
    ActiveAccountsToJSON,
} from './';

/**
 * A list of daily unique active account windows.
 * @export
 * @interface ActiveAccountsList
 */
export interface ActiveAccountsList {
    /**
     * 
     * @type {number}
     * @memberof ActiveAccountsList
     */
    window_size_seconds: number;
    /**
     * The list of daily unique active account windows.
     * @type {Array<ActiveAccounts>}
     * @memberof ActiveAccountsList
     */
    windows: Array<ActiveAccounts>;
}

export function ActiveAccountsListFromJSON(json: any): ActiveAccountsList {
    return ActiveAccountsListFromJSONTyped(json, false);
}

export function ActiveAccountsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveAccountsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'window_size_seconds': json['window_size_seconds'],
        'windows': ((json['windows'] as Array<any>).map(ActiveAccountsFromJSON)),
    };
}

export function ActiveAccountsListToJSON(value?: ActiveAccountsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'window_size_seconds': value.window_size_seconds,
        'windows': ((value.windows as Array<any>).map(ActiveAccountsToJSON)),
    };
}


