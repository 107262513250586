/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoothashMessageType {
    StakingTransfer = 'staking.transfer',
    StakingWithdraw = 'staking.withdraw',
    StakingAddEscrow = 'staking.add_escrow',
    StakingReclaimEscrow = 'staking.reclaim_escrow',
    RegistryUpdateRuntime = 'registry.update_runtime',
    GovernanceCastVote = 'governance.cast_vote',
    GovernanceSubmitProposal = 'governance.submit_proposal'
}

export function RoothashMessageTypeFromJSON(json: any): RoothashMessageType {
    return RoothashMessageTypeFromJSONTyped(json, false);
}

export function RoothashMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoothashMessageType {
    return json as RoothashMessageType;
}

export function RoothashMessageTypeToJSON(value?: RoothashMessageType | null): any {
    return value as any;
}

