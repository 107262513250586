/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValidatorCommissionSchedule,
    ValidatorCommissionScheduleFromJSON,
    ValidatorCommissionScheduleFromJSONTyped,
    ValidatorCommissionScheduleToJSON,
    ValidatorMediaInfo,
    ValidatorMediaInfoFromJSON,
    ValidatorMediaInfoFromJSONTyped,
    ValidatorMediaInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ValidatorRow
 */
export interface ValidatorRow {
    /**
     * 
     * @type {string}
     * @memberof ValidatorRow
     */
    account_id: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatorRow
     */
    account_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatorRow
     */
    node_id: string;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    escrow_balance: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    escrow_shares: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    general_balance: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    debonding_balance: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    delegations_balance: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    debonding_delegations_balance: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    self_delegation_balance: number;
    /**
     * 
     * @type {ValidatorMediaInfo}
     * @memberof ValidatorRow
     */
    media_info?: ValidatorMediaInfo;
    /**
     * 
     * @type {ValidatorCommissionSchedule}
     * @memberof ValidatorRow
     */
    commission_schedule?: ValidatorCommissionSchedule;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    current_epoch: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    day_uptime: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    total_uptime: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    validate_since: number;
    /**
     * If "active", an entity has a node that is registered for being a validator, node is up to date, and has successfully registered itself. However, it may or may not be part of validator set (top <scheduler.params.max_validators> by stake).
     * @type {string}
     * @memberof ValidatorRow
     */
    status: ValidatorRowStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    depositors_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    blocks_count: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorRow
     */
    signatures_count: number;
}

/**
* @export
* @enum {string}
*/
export enum ValidatorRowStatusEnum {
    Active = 'active',
    Inactive = 'inactive'
}

export function ValidatorRowFromJSON(json: any): ValidatorRow {
    return ValidatorRowFromJSONTyped(json, false);
}

export function ValidatorRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': json['account_id'],
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
        'node_id': json['node_id'],
        'escrow_balance': json['escrow_balance'],
        'escrow_shares': json['escrow_shares'],
        'general_balance': json['general_balance'],
        'debonding_balance': json['debonding_balance'],
        'delegations_balance': json['delegations_balance'],
        'debonding_delegations_balance': json['debonding_delegations_balance'],
        'self_delegation_balance': json['self_delegation_balance'],
        'media_info': !exists(json, 'media_info') ? undefined : ValidatorMediaInfoFromJSON(json['media_info']),
        'commission_schedule': !exists(json, 'commission_schedule') ? undefined : ValidatorCommissionScheduleFromJSON(json['commission_schedule']),
        'current_epoch': json['current_epoch'],
        'day_uptime': json['day_uptime'],
        'total_uptime': json['total_uptime'],
        'validate_since': json['validate_since'],
        'status': json['status'],
        'depositors_count': !exists(json, 'depositors_count') ? undefined : json['depositors_count'],
        'blocks_count': json['blocks_count'],
        'signatures_count': json['signatures_count'],
    };
}

export function ValidatorRowToJSON(value?: ValidatorRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'account_name': value.account_name,
        'node_id': value.node_id,
        'escrow_balance': value.escrow_balance,
        'escrow_shares': value.escrow_shares,
        'general_balance': value.general_balance,
        'debonding_balance': value.debonding_balance,
        'delegations_balance': value.delegations_balance,
        'debonding_delegations_balance': value.debonding_delegations_balance,
        'self_delegation_balance': value.self_delegation_balance,
        'media_info': ValidatorMediaInfoToJSON(value.media_info),
        'commission_schedule': ValidatorCommissionScheduleToJSON(value.commission_schedule),
        'current_epoch': value.current_epoch,
        'day_uptime': value.day_uptime,
        'total_uptime': value.total_uptime,
        'validate_since': value.validate_since,
        'status': value.status,
        'depositors_count': value.depositors_count,
        'blocks_count': value.blocks_count,
        'signatures_count': value.signatures_count,
    };
}


