/* tslint:disable */
/* eslint-disable */
/**
 * OasisExplorer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidatorStat
 */
export interface ValidatorStat {
    /**
     * 
     * @type {number}
     * @memberof ValidatorStat
     */
    timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorStat
     */
    availability_score?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorStat
     */
    blocks_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidatorStat
     */
    signatures_count?: number;
}

export function ValidatorStatFromJSON(json: any): ValidatorStat {
    return ValidatorStatFromJSONTyped(json, false);
}

export function ValidatorStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidatorStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'availability_score': !exists(json, 'availability_score') ? undefined : json['availability_score'],
        'blocks_count': !exists(json, 'blocks_count') ? undefined : json['blocks_count'],
        'signatures_count': !exists(json, 'signatures_count') ? undefined : json['signatures_count'],
    };
}

export function ValidatorStatToJSON(value?: ValidatorStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'availability_score': value.availability_score,
        'blocks_count': value.blocks_count,
        'signatures_count': value.signatures_count,
    };
}


