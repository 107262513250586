"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleEventHandler = exports.Wrapper = exports.EVENT_WITHDRAW_CODE = exports.EVENT_DEPOSIT_CODE = exports.METHOD_ACCOUNT = exports.METHOD_BALANCE = exports.METHOD_WITHDRAW = exports.METHOD_DEPOSIT = exports.ERR_INSUFFICIENT_WITHDRAW_BALANCE_CODE = exports.ERR_INVALID_DENOMINATION_CODE = exports.ERR_INVALID_ARGUMENT_CODE = exports.MODULE_NAME = void 0;
const wrapper = require("./wrapper");
/**
 * Unique module name.
 */
exports.MODULE_NAME = 'consensus_accounts';
exports.ERR_INVALID_ARGUMENT_CODE = 1;
exports.ERR_INVALID_DENOMINATION_CODE = 2;
exports.ERR_INSUFFICIENT_WITHDRAW_BALANCE_CODE = 3;
// Callable methods.
exports.METHOD_DEPOSIT = 'consensus.Deposit';
exports.METHOD_WITHDRAW = 'consensus.Withdraw';
// Queries.
exports.METHOD_BALANCE = 'consensus.Balance';
exports.METHOD_ACCOUNT = 'consensus.Account';
// Events.
exports.EVENT_DEPOSIT_CODE = 1;
exports.EVENT_WITHDRAW_CODE = 2;
class Wrapper extends wrapper.Base {
    constructor(runtimeID) {
        super(runtimeID);
    }
    callDeposit() {
        return this.call(exports.METHOD_DEPOSIT);
    }
    callWithdraw() {
        return this.call(exports.METHOD_WITHDRAW);
    }
    queryBalance() {
        return this.query(exports.METHOD_BALANCE);
    }
    queryAccount() {
        return this.query(exports.METHOD_ACCOUNT);
    }
}
exports.Wrapper = Wrapper;
function moduleEventHandler(codes) {
    return [exports.MODULE_NAME, codes];
}
exports.moduleEventHandler = moduleEventHandler;
