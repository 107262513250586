/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvmAbiParam,
    EvmAbiParamFromJSON,
    EvmAbiParamFromJSONTyped,
    EvmAbiParamToJSON,
    EvmEventToken,
    EvmEventTokenFromJSON,
    EvmEventTokenFromJSONTyped,
    EvmEventTokenToJSON,
    RuntimeEventType,
    RuntimeEventTypeFromJSON,
    RuntimeEventTypeFromJSONTyped,
    RuntimeEventTypeToJSON,
} from './';

/**
 * An event emitted by the runtime layer
 * @export
 * @interface RuntimeEvent
 */
export interface RuntimeEvent {
    /**
     * The block height at which this event was generated.
     * @type {number}
     * @memberof RuntimeEvent
     */
    round: number;
    /**
     * 0-based index of this event's originating transaction within its block.
     * Absent if the event did not originate from a transaction.
     * @type {number}
     * @memberof RuntimeEvent
     */
    tx_index?: number;
    /**
     * Hash of this event's originating transaction.
     * Absent if the event did not originate from a transaction.
     * @type {string}
     * @memberof RuntimeEvent
     */
    tx_hash?: string;
    /**
     * Ethereum trasnsaction hash of this event's originating transaction.
     * Absent if the event did not originate from an EVM transaction.
     * @type {string}
     * @memberof RuntimeEvent
     */
    eth_tx_hash?: string;
    /**
     * The second-granular consensus time of this event's block.
     * @type {Date}
     * @memberof RuntimeEvent
     */
    timestamp: Date;
    /**
     * The type of the event.
     * @type {RuntimeEventType}
     * @memberof RuntimeEvent
     */
    type: RuntimeEventType;
    /**
     * The decoded event contents, possibly augmented with additional address info.
     * This spec does not encode the many possible types; instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
     * This object will conform to one of the `*Event` types two levels down
     * the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
     * OR `evm > Event`. For object fields that specify an oasis-style address, Nexus
     * will add a field specifying the corresponding Ethereum address, if known. Currently,
     * the only such possible fields are `from_eth`, `to_eth`, and `owner_eth`.
     * @type {object}
     * @memberof RuntimeEvent
     */
    body: object;
    /**
     * If the event type is `evm.log`, this field describes the human-readable type of
     * evm event, e.g. `Transfer`.
     * Absent if the event type is not `evm.log`.
     * @type {string}
     * @memberof RuntimeEvent
     */
    evm_log_name?: string;
    /**
     * The decoded `evm.log` event data.
     * Absent if the event type is not `evm.log`.
     * @type {Array<EvmAbiParam>}
     * @memberof RuntimeEvent
     */
    evm_log_params?: Array<EvmAbiParam>;
    /**
     * 
     * @type {EvmEventToken}
     * @memberof RuntimeEvent
     */
    evm_token?: EvmEventToken;
}

export function RuntimeEventFromJSON(json: any): RuntimeEvent {
    return RuntimeEventFromJSONTyped(json, false);
}

export function RuntimeEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'round': json['round'],
        'tx_index': !exists(json, 'tx_index') ? undefined : json['tx_index'],
        'tx_hash': !exists(json, 'tx_hash') ? undefined : json['tx_hash'],
        'eth_tx_hash': !exists(json, 'eth_tx_hash') ? undefined : json['eth_tx_hash'],
        'timestamp': (new Date(json['timestamp'])),
        'type': RuntimeEventTypeFromJSON(json['type']),
        'body': json['body'],
        'evm_log_name': !exists(json, 'evm_log_name') ? undefined : json['evm_log_name'],
        'evm_log_params': !exists(json, 'evm_log_params') ? undefined : ((json['evm_log_params'] as Array<any>).map(EvmAbiParamFromJSON)),
        'evm_token': !exists(json, 'evm_token') ? undefined : EvmEventTokenFromJSON(json['evm_token']),
    };
}

export function RuntimeEventToJSON(value?: RuntimeEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'round': value.round,
        'tx_index': value.tx_index,
        'tx_hash': value.tx_hash,
        'eth_tx_hash': value.eth_tx_hash,
        'timestamp': (value.timestamp.toISOString()),
        'type': RuntimeEventTypeToJSON(value.type),
        'body': value.body,
        'evm_log_name': value.evm_log_name,
        'evm_log_params': value.evm_log_params === undefined ? undefined : ((value.evm_log_params as Array<any>).map(EvmAbiParamToJSON)),
        'evm_token': EvmEventTokenToJSON(value.evm_token),
    };
}


