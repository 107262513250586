/* tslint:disable */
/* eslint-disable */
/**
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.  <!-- Acts as a separator after search in sidebar --> # Endpoints 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Escrow,
    EscrowFromJSON,
    EscrowFromJSONTyped,
    EscrowToJSON,
    ValidatorCommissionBound,
    ValidatorCommissionBoundFromJSON,
    ValidatorCommissionBoundFromJSONTyped,
    ValidatorCommissionBoundToJSON,
    ValidatorMedia,
    ValidatorMediaFromJSON,
    ValidatorMediaFromJSONTyped,
    ValidatorMediaToJSON,
} from './';

/**
 * An validator registered at the consensus layer.
 * @export
 * @interface Validator
 */
export interface Validator {
    /**
     * The staking address identifying this validator.
     * @type {string}
     * @memberof Validator
     */
    entity_address: string;
    /**
     * The public key identifying this validator.
     * @type {string}
     * @memberof Validator
     */
    entity_id: string;
    /**
     * The public key identifying this validator's node.
     * @type {string}
     * @memberof Validator
     */
    node_id?: string;
    /**
     * The escrow account data for this validator.
     * @type {Escrow}
     * @memberof Validator
     */
    escrow: Escrow;
    /**
     * The voting power of this validator.
     * @type {number}
     * @memberof Validator
     */
    voting_power: number;
    /**
     * The cumulative voting power of this validator and all other validators ranked higher than itself.
     * @type {number}
     * @memberof Validator
     */
    voting_power_cumulative?: number;
    /**
     * Whether the entity has a node that is registered for being a validator, node is up to date, and has successfully registered itself. It may or may not be part of validator set.
     * @type {boolean}
     * @memberof Validator
     */
    active: boolean;
    /**
     * The second-granular consensus time.
     * @type {Date}
     * @memberof Validator
     */
    start_date: Date;
    /**
     * The rank of the validator, determined by voting power.
     * @type {number}
     * @memberof Validator
     */
    rank: number;
    /**
     * Whether the entity is part of the validator set (top <scheduler.params.max_validators> by stake among active entities).
     * @type {boolean}
     * @memberof Validator
     */
    in_validator_set: boolean;
    /**
     * 
     * @type {ValidatorMedia}
     * @memberof Validator
     */
    media?: ValidatorMedia;
    /**
     * Commission rate.
     * @type {number}
     * @memberof Validator
     */
    current_rate: number;
    /**
     * 
     * @type {ValidatorCommissionBound}
     * @memberof Validator
     */
    current_commission_bound: ValidatorCommissionBound;
}

export function ValidatorFromJSON(json: any): Validator {
    return ValidatorFromJSONTyped(json, false);
}

export function ValidatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Validator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_address': json['entity_address'],
        'entity_id': json['entity_id'],
        'node_id': !exists(json, 'node_id') ? undefined : json['node_id'],
        'escrow': EscrowFromJSON(json['escrow']),
        'voting_power': json['voting_power'],
        'voting_power_cumulative': !exists(json, 'voting_power_cumulative') ? undefined : json['voting_power_cumulative'],
        'active': json['active'],
        'start_date': (new Date(json['start_date'])),
        'rank': json['rank'],
        'in_validator_set': json['in_validator_set'],
        'media': !exists(json, 'media') ? undefined : ValidatorMediaFromJSON(json['media']),
        'current_rate': json['current_rate'],
        'current_commission_bound': ValidatorCommissionBoundFromJSON(json['current_commission_bound']),
    };
}

export function ValidatorToJSON(value?: Validator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_address': value.entity_address,
        'entity_id': value.entity_id,
        'node_id': value.node_id,
        'escrow': EscrowToJSON(value.escrow),
        'voting_power': value.voting_power,
        'voting_power_cumulative': value.voting_power_cumulative,
        'active': value.active,
        'start_date': (value.start_date.toISOString()),
        'rank': value.rank,
        'in_validator_set': value.in_validator_set,
        'media': ValidatorMediaToJSON(value.media),
        'current_rate': value.current_rate,
        'current_commission_bound': ValidatorCommissionBoundToJSON(value.current_commission_bound),
    };
}


